import { uniqueId } from "lodash";
import { observable, action, makeObservable } from "mobx";
import { useState } from "react";

export const useLoader = (promise?: Promise<any> | boolean, options = { immediate: false }) => {
    const [loader] = useState(new Loader(promise, options));
    return loader;
};

interface LoaderOptions {
    /** Whether or not to show the loader immediately, instead of waiting the default minimum threshold. */
    immediate: boolean;
}

/**
 * Minimizes flashes of loading spinners by making sure that loading=true is never true for
 * responses below a certain threshold, and is always true for at least a minimum amount of time
 * above that threshold.
 */
export default class Loader {
    @observable public loading: boolean = false;
    @observable public succeeded: boolean = false;
    @observable public recentlySucceeded: boolean = false;
    @observable public error?: any | null;
    @observable public promise?: Promise<any>;

    private startTimeout?: any;
    private stopTimeout?: any;
    private finishedPromise: boolean = false;
    private options: { immediate: boolean };
    private recentlySuceededTimeout?: any;

    public id;

    /**
     * @param promise Either the promise that this loader should track, or true if the loader should default to loaded.
     * @param options
     */
    constructor(promise?: Promise<any> | boolean, options: LoaderOptions = { immediate: false }) {
        makeObservable<Loader, "finish">(this);
        this.options = options;
        this.id = uniqueId();

        if (promise === true) {
            this.load(Promise.resolve(), true);
            return;
        }

        // if no promise is supplied, assume that this loader is just for tracking a default no-data state
        if (!promise) {
            this.promise = new Promise(_ => {});
            return;
        }

        this.load(promise);
    }

    loadAll(promises: Promise<any>[]): Promise<any> {
        return this.load(Promise.all(promises));
    }

    @action
    load<T>(promise: Promise<T>, suppressRecentSuccess = false): Promise<T> {
        this.stopTimeout && clearTimeout(this.stopTimeout);
        this.stopTimeout = undefined;
        this.finishedPromise = false;
        this.succeeded = false;
        this.recentlySucceeded = false;
        this.recentlySuceededTimeout && clearTimeout(this.recentlySuceededTimeout);
        this.recentlySuceededTimeout = undefined;

        if (this.options.immediate) {
            this.loading = true;
        } else {
            this.startTimeout = setTimeout(
                action(() => {
                    this.loading = true;
                    this.stopTimeout = setTimeout(
                        action(() => {
                            if (this.finishedPromise) {
                                this.loading = false;
                            }

                            this.stopTimeout = undefined;
                        }),
                        200
                    );
                }),
                50
            );
        }

        promise
            .then(res => {
                this.finish(null, suppressRecentSuccess);
                return res;
            })
            .catch(err => {
                this.finish(err);
            });

        this.promise = promise;
        return promise;
    }

    @action
    protected finish(err, suppressRecentSuccess = false): void {
        this.error = err;
        this.finishedPromise = true;
        if (!this.error) {
            this.succeeded = true;
            if (!suppressRecentSuccess) {
                this.recentlySucceeded = true;
                this.recentlySuceededTimeout = setTimeout(
                    action(() => {
                        this.recentlySuceededTimeout = undefined;
                        this.recentlySucceeded = false;
                    }),
                    1000
                );
            }
        }
        clearTimeout(this.startTimeout);
        if (!this.stopTimeout) {
            this.loading = false;
        }
    }
}
