import React from "react";
import { Route, Routes } from "react-router-dom";
import JiraConnectConfiguration from "./views/JiraConnectConfiguration";
import JiraIntegration from "./views/JiraIntegration";

export default class JiraConnectRoutes extends React.Component<any, {}> {
    render() {
        return (
            <Routes>
                <Route path="configure" element={<JiraConnectConfiguration />} />
                <Route path="integration" element={<JiraIntegration />} />
            </Routes>
        );
    }
}
