import styled from "styled-components";
import {
    List as SemanticList,
    Container as SemanticContainer,
    Form as SemanticForm,
    Progress as SemanticProgress
} from "semantic-ui-react";

export const Progress = styled(SemanticProgress)`
    border-radius: 99px !important;

    .bar {
        border-radius: 99px !important;
    }
`;

export const Container = styled(SemanticContainer)`
    width: 860px !important;
`;

export const Box = styled.div`
    border: 1px solid rgba(34, 36, 38, 0.15);
    & > .grid > .column {
        padding: 4em 2em !important;
    }
`;

export const List = styled(SemanticList)`
    padding-top: 2em !important;

    .icon {
        color: #228b22;
    }

    .item:not(:last-child) {
        margin-bottom: 1em;
    }
`;

export const Contact = styled.p`
    text-align: center;
    margin-top: 40px;
`;

export const Form = styled(SemanticForm)`
    /* .input {
        border-bottom: 2px solid #000;

        input {
            border: 0px !important;
            padding: 0.678571em 0em !important;
        }
    } */

    .button.primary {
        margin-top: 20px;
    }
`;

export const Note = styled.p`
    color: #8e9094;
    text-align: center;
    font-size: 12px;
    margin-top: 20px;

    a {
        color: #8e9094;
        text-decoration: underline;
        cursor: pointer;
    }
`;
