import React from "react";

export default class Privacy extends React.Component {
    render() {
        return (
            <div style={{ margin: 40 }}>
                <h1>Aberrant's Privacy Policy</h1>
                <h2>Information we collect from you</h2>
                <p>
                    When you interact with us—whether on our websites, on our mobile apps, at events Aberrant attends,
                    organizes or sponsors, or in our branches or offices—we may collect certain personal information
                    about you. For example:
                </p>
                <ul>
                    <li>
                        When you apply for our products, we may collect information such as your name, address, phone
                        number, and email address.
                    </li>
                    <li>
                        When you visit our websites or use our mobile apps, we may collect information such as your
                        computer IP address, your device identifier, your location, and what pages you view or visit.
                    </li>
                </ul>
                <h2>How we use your information</h2>
                <p>
                    We use your personal information to support products and services that you use or that we offer to
                    you. For example, we may use your contact information to market our products and services to you.
                </p>
                <h2>When and why we share your information</h2>
                <p>
                    We may disclose certain personal information to our service providers and other trusted business
                    partners to support our products and services as permitted by law. We may also disclose your
                    personal information to others upon your request. For example, we may disclose certain personal
                    information to third parties to:
                </p>
                <ul>
                    <li>Perform services, such as marketing or market research, on our behalf.</li>
                    <li>Service, maintain, or process your transactions and accounts.</li>
                    <li>Cooperate with government agencies and law enforcement officials.</li>
                </ul>
                <h2>Contact Us</h2>
                <p>
                    For more information about our privacy practices, or if you have questions or concerns, please
                    contact us at <a href="mailto:info@aberrant.io">info@aberrant.io</a>.
                </p>
            </div>
        );
    }
}
