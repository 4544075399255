import request from "util/request";
import { useState, useContext, useEffect } from "react";
import { contexts } from "AppProvider";

export interface Assignee {
    id: string;
    name: string;
}

export interface UserAssignee extends Assignee {
    isServiceAccount: boolean;
}

export interface GroupAssignee extends Assignee {}

export const useAssignees = () => {
    const assigneeStore = useContext(contexts).assignee;
    const [users, setUsers] = useState<Map<string, UserAssignee>>(new Map());
    const [groups, setGroups] = useState<Map<string, GroupAssignee>>(new Map());

    const userPromise = assigneeStore?.users();
    const groupPromise = assigneeStore?.groups();

    useEffect(() => {
        let isSubscribed = true;
        userPromise?.then((assignees) => {
            if (isSubscribed) {
                setUsers(assignees);
            }
        });
        groupPromise?.then((assignees) => {
            if (isSubscribed) {
                setGroups(assignees);
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, [userPromise, groupPromise]);

    return { users, groups };
};

export default class AssigneeStore {
    private _users?: Promise<Map<string, UserAssignee>>;
    private _groups?: Promise<Map<string, GroupAssignee>>;

    clear() {
        this._groups = undefined;
        this._users = undefined;
    }

    groups(): Promise<Map<string, GroupAssignee>> {
        if (!this._groups) {
            this._groups = request()
                .get(`/api/entitygroup`)
                .handle()
                .then((res) => new Map(res.body.map((a) => [a.id, a])));
        }

        return this._groups!;
    }

    users(): Promise<Map<string, UserAssignee>> {
        if (!this._users) {
            this._users = request()
                .get(`/api/entityuser`)
                .handle()
                .then((res) => new Map(res.body.map((a) => [a.id, a])));
        }

        return this._users!;
    }
}
