import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { contexts } from "AppProvider";

export default observer(() => {
    const session = useContext(contexts).session;
    const logoutUrl = session?.useExternalAuth ? "/api/session/external/logout" : "/api/session/logout";
    return (
        <div style={{ position: "absolute", top: 20, right: 20 }}>
            <a href={logoutUrl}>Logout</a>
        </div>
    );
});
