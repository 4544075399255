import { initializeStores, StoreInstanceByName } from "initializeStores";
import React, { useEffect, useState } from "react";

export const contexts = React.createContext<Partial<StoreInstanceByName>>({});
export const AppProvider = ({ children }) => {
    const [instances] = useState(() => initializeStores());

    useEffect(() => {
        window["__contexts"] = instances;
    }, [instances]);

    return <contexts.Provider value={instances}>{children}</contexts.Provider>;
};
