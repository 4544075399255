import React, { useEffect, useState } from "react";
import styles from "./Badge.module.css";
import { useAssignees } from "shared/models/workflow/AssigneeStore";
import { Icon } from "semantic-ui-react";

export default ({ assigneeId, defaultName = "Unassigned" }: { assigneeId?: string; defaultName?: string }) => {
    const { users, groups } = useAssignees();
    const [userName, setUserName] = useState("Loading...");
    const [groupName, setGroupName] = useState<string | undefined>();

    useEffect(() => {
        if (assigneeId) {
            const user = users.get(assigneeId);
            setUserName(user ? user.name : defaultName);
            if (!user) {
                setGroupName(groups.get(assigneeId)?.name);
            }
        } else {
            setUserName(defaultName);
        }
    }, [users, groups, assigneeId]);

    if (groupName) {
        return (
            <span className={styles.assigneeBadge}>
                <Icon name="group" />
                {groupName}
            </span>
        );
    } else {
        return <span className={styles.assigneeBadge}>{userName}</span>;
    }
};
