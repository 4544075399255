import React from "react";
import gtmParts from "react-google-tag-manager";

class GoogleTagManager extends React.Component {
    componentDidMount() {
        if (window && !window["dataLayer"]) {
            const gtmScriptNode = document.getElementById("react-google-tag-manager-gtm");
            // eslint-disable-next-line no-eval
            eval(gtmScriptNode.textContent);
        }
    }

    render() {
        const gtm = gtmParts({ id: this.props.containerId });
        return (
            <React.Fragment>
                {gtm.noScriptAsReact()}
                <div id="react-google-tag-manager-gtm">{gtm.scriptAsReact()}</div>
            </React.Fragment>
        );
    }
}

export default GoogleTagManager;
