import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Container, Button, Form, Message, Icon } from "semantic-ui-react";
import request from "util/request";
import { forEach } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { PasswordChecklist } from "shared/components/PasswordChecklist";

interface FormData {
    password: string;
    confirmPassword: string;
}

export default observer(() => {
    const navigate = useNavigate();
    const token = new URLSearchParams(useLocation().search).get("token");
    if (!token) {
        throw new Error("Email verification does not have a token");
    }
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        watch,
        formState: { isSubmitting }
    } = useForm<FormData>();
    const [formError, setFormError] = useState<string>();

    const confirmPassword = watch("confirmPassword", "");
    const password = watch("password", "");
    const onSubmit = (data: FormData) => {
        return request()
            .post("/api/session/resetpassword")
            .send({ ...data, token })
            .handle((err, res) => {
                if (err) {
                    if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof FormData, { message });
                        });
                    } else {
                        setFormError("There was an error resetting your password.");
                    }
                    return;
                }

                navigate("/login", { replace: true });
            })
            .catch((err) => {
                // don't bubble these errors to sentry
                if (err.status === 400) {
                } else {
                    throw err;
                }
            });
    };
    return (
        <Container text>
            <Message attached info>
                <Message.Header>
                    <Icon name="redo" /> Reset Password
                </Message.Header>
            </Message>
            {formError && (
                <Message attached negative>
                    <Message.Header>Reset Password Failed</Message.Header>
                    {formError}
                </Message>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} className="attached segment">
                <Controller
                    name="password"
                    render={({ field }) => {
                        return (
                            <Form.Input
                                value={field.value}
                                onChange={field.onChange}
                                label="New Password"
                                type="password"
                                error={errors.password && { content: errors.password.message }}
                            />
                        );
                    }}
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "Password is required",
                        minLength: {
                            value: 10,
                            message: "Password must be at least 10 characters"
                        },
                        pattern: {
                            value: new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{10,}$"),
                            message: "Password must have at least one numeric, alpha, uppercase or non-alpha character+"
                        }
                    }}
                />
                <Controller
                    name="confirmPassword"
                    render={({ field }) => {
                        return (
                            <Form.Input
                                value={field.value}
                                onChange={field.onChange}
                                label="Confirm Password"
                                type="password"
                                error={errors.confirmPassword && { content: errors.confirmPassword.message }}
                            />
                        );
                    }}
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "Re-entering the password is required",
                        validate: (value) => (value !== watch("password") ? "Passwords must match" : undefined)
                    }}
                />
                <PasswordChecklist value={password} valueAgain={confirmPassword} />
                <Button loading={isSubmitting} type="submit">
                    Reset Password
                </Button>
            </Form>
        </Container>
    );
});
