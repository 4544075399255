import React from "react";
import styles from "./Badge.module.css";

export default ({ label, background, color }: { label: string; background?: string; color?: string }) => {
    return (
        <>
            <span
                className={styles.badge}
                style={{
                    color: color,
                    background: background || "#aaa"
                }}
            >
                {label}
            </span>
        </>
    );
};
