import { action, autorun, computed, makeObservable, observable } from "mobx";
import { Item } from "./Item";
import { useContext, useState, useEffect } from "react";
import { contexts } from "AppProvider";
import request from "util/request";
import Loader, { useLoader } from "util/Loader";

export const useSpaces = (): [Item[], Loader, SpaceStore] => {
    const spaceStore = useContext(contexts).space;
    const [spaces, setSpaces] = useState<Item[]>([]);
    const loader = useLoader();

    useEffect(() => {
        let isSubscribed = true;
        const disposer = autorun(() => {
            if(spaceStore) {
                loader.load(
                    spaceStore.spaces.then(spaces => {
                        if (isSubscribed) {
                            setSpaces(spaces);
                        }
                    })
                );
            }
        });
        return () => {
            disposer();
            isSubscribed = false;
        };
    }, []);

    return [spaces, loader, spaceStore!];
};

export default class SpaceStore {
    @observable private _spaces?: Promise<Item[]>;

    constructor() {
        makeObservable<SpaceStore, "_spaces">(this);
    }

    @computed
    get spaces(): Promise<Item[]> {
        if (!this._spaces) {
            this.reload();
        }
        return this._spaces!;
    }

    @action.bound
    reload() {
        this._spaces = request()
            .get<Item[]>(`/api/space`)
            .handle(err => {
                if (err) return;
            })
            .then(res => res.body);
    }
}
