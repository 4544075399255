import { ItemType } from "shared/models/Item";

export default function getTypeNameHeader(item: { type: ItemType }, plural = true): string {
    const type = item.type;

    switch (type) {
        case ItemType.Tag:
            return plural ? "Tags" : "Tag";
        case ItemType.Document:
            return plural ? "Documents" : "Document";
        case ItemType.Standard:
            return plural ? "Standards" : "Standard";
        case ItemType.Task:
            return plural ? "Tasks" : "Task";
        case ItemType.PseudoTask:
            return plural ? "Tasks" : "Task";
        case ItemType.Action:
            return plural ? "Actions" : "Action";
        case ItemType.Section:
            return plural ? "Sections" : "Section";
        case ItemType.Control:
            return plural ? "Controls" : "Control";
    }

    return "Other";
}
