import React from "react";
import ForgotPasswordForm from "../components/ForgotPasswordForm";
import PublicPage from "shared/components/content/PublicPage";

export default () => {
    return (
        <PublicPage title="Forgot Password">
            <ForgotPasswordForm />
        </PublicPage>
    );
};
