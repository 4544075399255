import React from "react";
import ReactPasswordChecklist, { ReactPasswordChecklistProps } from "react-password-checklist";

type PasswordChecklistProps = Omit<ReactPasswordChecklistProps, "rules"> & {
    rules?: ReactPasswordChecklistProps["rules"];
};

export const PasswordChecklist = ({ rules, ...props }: PasswordChecklistProps) => {
    return (
        <ReactPasswordChecklist
            style={{ marginTop: 8, marginBottom: 16 }}
            rules={rules || ["minLength", "specialChar", "letter", "number", "capital", "match"]}
            minLength={10}
            {...props}
        />
    );
};
