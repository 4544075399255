import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/logo.png";
import notFound404Image from "assets/404.svg";

import { HeaderLogoWrapper, PageHeader } from "shared/components/content/Page";
import { dashboardPathByName } from "dashboard/constants";
import { FormattedMessage } from "react-intl";

const colors = {
    primary: "#8D40C5"
};
const PageNotFound = () => {
    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", position: "relative" }}>
            <PageHeader className="page-header" style={{ position: "sticky", top: 0, left: 0, right: 0 }}>
                <HeaderLogoWrapper>
                    <Link to="/">
                        <img alt="Aberrant Logo" src={logo} width="36" />
                    </Link>
                </HeaderLogoWrapper>
            </PageHeader>
            <div style={{ paddingTop: "15dvh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: 564, maxWidth: "100%", padding: 12 }}>
                    <div style={{ marginBottom: 15, width: 360, height: 260, maxWidth: "100%", maxHeight: "100%" }}>
                        <img alt="404" src={notFound404Image} style={{ width: "100%", height: "100%" }} />
                    </div>
                    <h2
                        style={{
                            textTransform: "uppercase",
                            color: colors.primary,
                            fontSize: 64,
                            lineHeight: 1.3,
                            fontWeight: 500
                        }}
                    >
                        <FormattedMessage description="not found page header" defaultMessage="Page not found" />
                    </h2>
                    <div style={{ fontWeight: 500 }}>
                        <p>
                            <FormattedMessage defaultMessage="The page you are looking for cannot be found." />
                        </p>
                        <p>
                            <FormattedMessage defaultMessage="It may have been moved, deleted, or the URL was entered incorrectly." />
                        </p>
                        <a href={dashboardPathByName.home}>
                            <FormattedMessage
                                description="link to homepage text on 404 page"
                                defaultMessage="Click here to return to the home page"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
