import React from "react";
import TwoFactorStore from "../models/TwoFactorStore";
import Page from "shared/components/content/Page";
import TwoFactorForm from "../components/TwoFactorForm";
import TwoFactorMethodSelector from "../components/TwoFactorMethodSelector";
import { useObservingView } from "util/view-hooks";
import { Container } from "semantic-ui-react";
import { Logo } from "session/Styles";
import FloatingLogoutButton from "session/components/FloatingLogoutButton";

export default () =>
    useObservingView(
        () => new TwoFactorStore(),
        store => (
            <Page title="Two Factor Authentication">
                <FloatingLogoutButton />
                <Container text style={{ padding: "2em 0" }}>
                    {/* TODO: Replace this with real logo */}
                    <Logo as="h1" textAlign="center">
                        aberrant
                    </Logo>
                    {!store.codeSent && store.methodOptions && store.methodOptions.length > 0 && (
                        <TwoFactorMethodSelector store={store} />
                    )}
                    <TwoFactorForm isSetup={false} />
                </Container>
            </Page>
        )
    );
