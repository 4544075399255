import { observable, action, makeObservable } from "mobx";
import { uniqueId } from "lodash";
import moment from "moment";
import { Node as ProsemirrorNode, Node } from "prosemirror-model";
import { schema } from "editor/schema";

export enum WorkflowStatusType {
    None = 0,
    Unscoped = 1,
    InScope = 2,
    Ready = 3,
    OutOfScope = 5,
    Open = 6,
    InProgress = 7,
    ReviewRequested = 8,
    Approved = 9,
    Rejected = 10,
    ChangesRequested = 11,
    Closed = 12,
    CloseRequested = 13,
    Incomplete = 14,

    /** @deprecated */
    EvidenceAdded = 15,
    /** @deprecated */
    ExplanationAdded = 16,
    /** @deprecated */
    ExplanationApproved = 17,

    NoEvidenceRequired = 18,
    Published = 19,
    Future = 20,
    IssueOpen = 21,
    IssueRiskAccepted = 22,
    IssueResolved = 23,
    IssueReadyToTest = 24,
    Implemented = 25,
    EvidenceRequired = 26,
    IncludedInAudit = 27,
    ExcludedFromAudit = 28,
    Validated = 29,
    NotValidated = 30
}

export enum WorkflowTransitionFailure {
    NotComplete = 0,
    /** @deprecated */
    NeedsEvidence = 1,
    /** @deprecated */
    NeedsExplanation = 2,
    /** @deprecated */
    ExplanationNeedsApproval = 3,
    TaskCompleted = 4
}

export enum WorkflowType {
    None = 0,
    Control = 1,
    Review = 2,
    Implement = 3,
    TaskStep = 4,
    Issue = 5,
    Pseudo = 6
}

export class WorkflowStamp {
    key: string;

    constructor(props) {
        this.key = props.key || uniqueId(Date.now() + "-");
    }

    serialize() {
        return {
            key: this.key
        };
    }
}

export class Workflow {
    @observable itemId: string;
    @observable type: WorkflowType;
    @observable endUtc?: Date | null;
    @observable startUtc?: Date | null;
    @observable localKey: number;
    @observable completedUtc?: Date | null;
    @observable status: WorkflowStatusType;
    @observable draftStatus?: WorkflowStatusType;
    @observable assigneeId: any; // user / group / unassigned
    @observable reviewItemId: string;
    @observable explanation?: ProsemirrorNode;
    @observable draftExplanation?: ProsemirrorNode;
    @observable risk?: number;

    // @observable stamps: IObservableArray<WorkflowStamp>;

    constructor(props) {
        makeObservable(this);
        this.itemId = props.itemId;
        this.localKey = props.localKey;
        this.type = props.type;
        // this.stamps = observable.array(props.stamps.map(stamp => new WorkflowStamp(stamp)));
        this.endUtc = props.endUtc ? moment.utc(props.endUtc).toDate() : null;
        this.startUtc = props.startUtc ? moment.utc(props.startUtc).toDate() : null;
        this.completedUtc = props.completedUtc ? moment.utc(props.completedUtc).toDate() : null;
        this.status = props.status;
        this.draftStatus = props.draftStatus;
        this.assigneeId = props.assigneeId || "";
        this.reviewItemId = props.reviewItemId;
        this.risk = props.risk;

        if (props && props.explanation) {
            this.explanation = Node.fromJSON(schema, JSON.parse(props.explanation));
        }
        if (props && props.draftExplanation) {
            this.draftExplanation = Node.fromJSON(schema, JSON.parse(props.draftExplanation));
        }
    }

    @action
    setEndUtc(endUtc?: Date | null) {
        // set to end of current day
        this.endUtc = endUtc
            ? moment(endUtc)
                  .endOf("d")
                  .toDate()
            : null;
    }

    @action
    setStartUtc(startUtc?: Date | null) {
        this.startUtc = startUtc;
    }

    @action
    assign(assigneeId) {
        this.assigneeId = assigneeId;
    }

    @action
    setRisk(risk) {
        this.risk = risk;
    }

    @action
    transitionDraftStatus(newStatus?: WorkflowStatusType) {
        if (newStatus === this.status) {
            this.draftStatus = undefined;
        } else {
            this.draftStatus = newStatus;
        }
    }

    @action
    transitionStatus(newStatus: WorkflowStatusType) {
        this.status = newStatus;
        if (endTypes[this.type].includes(this.status)) {
            if (!this.completedUtc) {
                this.completedUtc = moment.utc().toDate();
            }
        } else {
            this.completedUtc = null;
        }
    }

    @action.bound
    changeExplanation(doc?: ProsemirrorNode) {
        if (doc && doc.textContent.length > 0) {
            this.draftExplanation = doc;
        } else {
            this.draftExplanation = undefined;
        }
    }

    // @action.bound
    // addNewStamp() {
    //     this.stamps.push(new WorkflowStamp({ type: startType[this.type] }));
    // }

    serialize() {
        return {
            // stamps: this.stamps.map(stamp => stamp.serialize()),
            itemId: this.itemId,
            localKey: this.localKey,
            type: this.type,
            endUtc: this.endUtc,
            startUtc: this.startUtc,
            completedUtc: this.completedUtc,
            status: this.status,
            assigneeId: this.assigneeId,
            reviewItemId: this.reviewItemId,
            draftStatus: this.draftStatus,
            explanation: JSON.stringify(this.explanation?.toJSON()),
            draftExplanation: JSON.stringify(this.draftExplanation?.toJSON()),
            risk: this.risk
        };
    }
}

export const endTypes = {
    [WorkflowType.Control]: Object.freeze([]),
    [WorkflowType.Implement]: Object.freeze([WorkflowStatusType.Ready, WorkflowStatusType.Closed]),
    [WorkflowType.Review]: Object.freeze([WorkflowStatusType.Approved, WorkflowStatusType.Rejected]),
    [WorkflowType.TaskStep]: Object.freeze([WorkflowStatusType.Ready, WorkflowStatusType.NoEvidenceRequired]),
    [WorkflowType.Issue]: Object.freeze([WorkflowStatusType.IssueResolved]),
    [WorkflowType.Pseudo]: Object.freeze([])
};

export const WorkflowFactory = {
    control(): Workflow {
        return new Workflow({
            status: WorkflowStatusType.Open,
            type: WorkflowType.Control
        });
    },
    implement(): Workflow {
        return new Workflow({
            status: WorkflowStatusType.Open,
            type: WorkflowType.Implement
        });
    },
    review(): Workflow {
        return new Workflow({
            status: WorkflowStatusType.ReviewRequested,
            type: WorkflowType.Review
        });
    }
};
