import { observable, makeObservable, action } from "mobx";
import Loader from "util/Loader";
import request from "util/request";

class TwoFactorSetupStore {
    @observable loader = new Loader(false);
    @observable manualCode;
    @observable qrCode;
    @observable methodOptions;
    @observable codeSent = false;
    @observable isMethodSelectorVisible = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setCodeSent(value) {
        this.codeSent = value;
    }

    @action.bound
    showMethodSelector() {
        this.isMethodSelectorVisible = true;
    }

    load() {
        this.loader.load(
            request()
                .get("/api/session/twofactor/setup")
                .handle((err, res) => {
                    if (err) return;
                    this.manualCode = res.body.manualCode;
                    this.qrCode = res.body.imageSrc;
                    this.methodOptions = res.body.methodOptions;
                })
        );

        return Promise.resolve();
    }
}

export default TwoFactorSetupStore;
