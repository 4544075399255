import SessionStore from "shared/models/SessionStore";
import AssigneeStore from "shared/models/workflow/AssigneeStore";
import { set as setSession } from "util/getSession";
import LayoutStore from "shared/models/LayoutStore";
import ThirdPartyIntegrationStore from "shared/models/ThirdPartyIntegrationStore";
import SpaceStore from "shared/models/SpaceStore";
import StandardStore from "shared/models/StandardStore";
import NotificationStore from "shared/models/NotificationStore";

export const initializeStores = () => {
    const notificationStore = new NotificationStore();
    const sessionStore = new SessionStore();
    setSession(sessionStore);

    sessionStore.load().then(() => {
        if (sessionStore.isReady) {
            notificationStore.load();
        }
    });

    const instances = {
        session: sessionStore,
        assignee: new AssigneeStore(),
        layout: new LayoutStore(),
        space: new SpaceStore(),
        standard: new StandardStore(),
        thirdPartyIntegration: new ThirdPartyIntegrationStore(),
        notification: notificationStore
    };

    return instances;
}

export type StoreInstanceByName = ReturnType<typeof initializeStores>;
