import React, { useState } from "react";
import { Header } from "semantic-ui-react";
import { Logo, SentBox } from "../Styles";
import sent from "../../assets/sent.svg";
import Page from "shared/components/content/Page";
import VerificationRequiredStore from "session/models/VerificationRequiredStore";
import { observer } from "mobx-react-lite";
import FloatingLogoutButton from "session/components/FloatingLogoutButton";

const VerificationRequired = () => {
    const [store] = useState(new VerificationRequiredStore());

    return (
        <Page style={{ display: "block" }} title="Verification">
            <FloatingLogoutButton />
            {/* TODO: Replace this with real logo */}
            <Logo as="h1" textAlign="center">
                aberrant
            </Logo>
            <SentBox>
                <img src={sent} alt="Paper airplane" />
                <Header as="h2">
                    <Header.Content>
                        Check your inbox for a verification email
                        <Header.Subheader>
                            Didn't receive an email from us?{" "}
                            <button
                                className="ui link"
                                type="submit"
                                disabled={!store.canResend}
                                onClick={() => store.resendVerificationEmail()}
                            >
                                Click here to resend
                            </button>{" "}
                            or <a href="mailto:support@aberrant.io">Contact us</a>
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            </SentBox>
        </Page>
    );
};

export default observer(VerificationRequired);
