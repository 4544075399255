import React from "react";
import { useObservingView } from "util/view-hooks";
import JiraConnectForm from "../components/JiraConnectForm";
import JiraConnectStore from "jira-connect-app/JiraConnectStore";
import { JiraIntegrationStatus } from "shared/models/JiraIntegration";
import { Icon, Loader, Segment } from "semantic-ui-react";
import PublicPage from "shared/components/content/PublicPage";

export default () => {
    return useObservingView(
        () => new JiraConnectStore(),
        store => {
            if (store.integrationStatus === JiraIntegrationStatus.NOT_QUERIED) {
                return <Loader size="massive">Loading</Loader>;
            }

            let message: any = "Your integration is loading.";
            if (store.integrationStatus === JiraIntegrationStatus.WAITING_FOR_CONNECTION) {
                return <JiraConnectForm store={store} />;
            } else {
                if (store.integrationStatus === JiraIntegrationStatus.CONNECTED) {
                    message = (
                        <>
                            <Icon color="green" name="check circle" />
                            Successfully connected. You can now preview Jira Issues within Aberrant.
                        </>
                    );
                } else if (store.integrationStatus === JiraIntegrationStatus.NOT_INSTALLED) {
                    message = (
                        <>
                            <Icon color="red" name="times circle" />
                            Oops! There was an error with your installation. Please try reinstalling the Jira Addon.
                        </>
                    );
                }
            }

            return (
                <PublicPage title="Jira" hideHeader hideFooter>
                    <Segment basic>
                        <h1>Configure your Jira Connection</h1>
                        <p>{message}</p>
                    </Segment>
                </PublicPage>
            );
        }
    );
};
