import english from "compiled-lang/en.json";
import french from "compiled-lang/fr.json";
import SessionStore from "shared/models/SessionStore";

export enum LanguageId {
    Default = 0,
    En_Us = 1,
    Fr = 2
}

export function getSelectedLangauge(session?: SessionStore) {
    const languageId = session?.languageId || LanguageId.Default;
    const qs = new URLSearchParams(window.location.search);
    const languageOverride = qs.has("lang") ? parseInt(qs.get("lang")!) : null;
    return languageOverride || languageId;
}

export function getLocaleCode(languageId: LanguageId) {
    switch (languageId) {
        case LanguageId.Fr:
            return "fr";
        case LanguageId.Default:
        case LanguageId.En_Us:
        default:
            return "en";
    }
}

export function getLocaleStrings(languageId: LanguageId) {
    switch (languageId) {
        case LanguageId.Fr:
            return french;
        case LanguageId.Default:
        case LanguageId.En_Us:
        default:
            return english;
    }
}
