import { observable, makeObservable } from "mobx";
import request from "util/request";
import { toast } from "react-toastify";

export default class VerificationStore {
    @observable canResend?: boolean;

    constructor() {
        makeObservable(this);
        this.canResend = true;
    }

    resendVerificationEmail() {
        this.canResend = false;
        setTimeout(() => {
            this.canResend = true;
        }, 5000);

        return request()
            .post("/api/registration/resend")
            .send({})
            .handle(err => {
                if (err) {
                    toast.error("Error resending verification email. ");
                } else {
                    toast.success("Verification email resent");
                }
            });
    }
}
