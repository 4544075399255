import SessionStore from "shared/models/SessionStore";

let session: SessionStore | undefined = undefined;

export function set(s: SessionStore): void {
    session = s;
}

export default (): SessionStore => {
    if (!session) {
        throw new Error("Session has not been set");
    }
    return session;
};
