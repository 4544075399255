import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const DeletionIconStyled = styled(Icon)`
    cursor: pointer;
    color: #767676;

    &:hover {
        color: #9e1a1a;
    }
`;

interface DeletionIconProps {
    onClick: () => void;
}

const DeletionIcon: React.FC<DeletionIconProps> = ({ onClick }) => (
    <DeletionIconStyled name="remove circle" fitted onClick={onClick} />
);

export default DeletionIcon;
