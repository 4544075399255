import styled from "styled-components";
import { Header as SemanticHeader } from "semantic-ui-react";

export const Logo = styled(SemanticHeader)`
    margin-bottom: 40px;
    padding-top: 24px;
    text-align: center;
    font-size: 37px;
`;

export const SentBox = styled.div`
    text-align: center;

    img {
        margin: 35px;
    }

    h1 > .content > .sub.header {
        margin-top: 15px;
    }
`;
