import React, { useContext } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { contexts } from "AppProvider";
import NotFound from "./NotFound";
// session routes
import Login from "session/views/Login";
import TwoFactor from "session/views/TwoFactor";
import TwoFactorSetup from "session/views/TwoFactorSetup";
import VerifyPhone from "session/views/VerifyPhone";
import VerifyEmail from "session/views/VerifyEmail";
import VerificationRequired from "session/views/VerificationRequired";
import ResetPassword from "session/views/ResetPassword";
import ForgotPassword from "session/views/ForgotPassword";
import JiraConnectRoutes from "jira-connect-app/JiraConnectRoutes";
import ChangeAccount from "session/views/ChangeAccount";
import AcceptInvitation from "registration/views/AcceptInvitation";

import loadable from "@loadable/component";
import { ViewLoader } from "util/view-hooks";
import { useLocation } from "react-router-dom";
import Privacy from "dashboard/views/Privacy";
import Terms from "dashboard/views/Terms";

const AdminRoutes = loadable(() => import("./admin/AdminRoutes"), {
    fallback: <ViewLoader />
});
const EntityAdminRoutes = loadable(() => import("./admin/EntityAdminRoutes"), {
    fallback: <ViewLoader />
});
const DashboardRoutes = loadable(() => import("./dashboard/DashboardRoutes"), {
    fallback: <ViewLoader />
});
const StackRoutes = loadable(() => import("./stack/StackRoutes"), {
    fallback: <ViewLoader />
});

const RedirectSessionWithReturnUrl = ({ session }) => {
    const loginUrl = session.useExternalAuth ? "/api/session/login" : "/login";
    const location = useLocation();
    if (session.useExternalAuth) {
        window.location.replace(loginUrl);
        return null;
    }

    const returnUrl =
        new URLSearchParams(location.search).get("return") ?? encodeURIComponent(location.pathname + location.search);
    return <Navigate replace to={`${loginUrl}?return=${returnUrl}`} />;
};

const RedirectWithReturnUrl = ({ path }) => {
    const location = useLocation();
    const returnUrl =
        new URLSearchParams(location.search).get("return") ?? encodeURIComponent(location.pathname + location.search);
    return <Navigate replace to={`${path}?return=${returnUrl}`} />;
};

export default observer(() => {
    const session = useContext(contexts)?.session;
    if (!session?.isAuthenticated) {
        return (
            <Routes>
                <Route path="/" element={<RedirectSessionWithReturnUrl session />} />
                <Route path="/jiraConnectApp" element={<JiraConnectRoutes />} />
                <Route path="/register/invite" element={<AcceptInvitation />} />
                <Route path="/login" element={<Login />} />
                <Route path="/session/forgotpassword" element={<ForgotPassword />} />
                <Route path="/session/resetpassword" element={<ResetPassword />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        );
    } else if (session?.isVerificationRequired) {
        const path: string = "/session/verify/required";
        return (
            <Routes>
                <Route path="/" element={<RedirectWithReturnUrl path={path} />} />
                <Route path={path} element={<VerificationRequired />} />
                <Route path="/session/verify/phone" element={<VerifyPhone />} />
                <Route path="/session/verify/email" element={<VerifyEmail />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        );
    } else if (session?.isTwoFactorRequired) {
        const path: string = session?.needsTwoFactorSetup ? "/session/twofactor/setup" : "/session/twofactor";
        return (
            <Routes>
                <Route path="/" element={<RedirectWithReturnUrl path={path} />} />
                <Route path="/session/twofactor/setup" element={<TwoFactorSetup />} />
                <Route path="/session/twofactor" element={<TwoFactor />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        );
    }

    if (session.canSeeMspFeatures) {
        return (
            <React.Fragment>
                <Routes>
                    <Route path="*" element={<DashboardRoutes />} />
                    <Route path="/admin/*" element={<AdminRoutes />} />
                    <Route path="/entity-admin/*" element={<EntityAdminRoutes />} />
                    <Route path="/stack/*" element={<StackRoutes />} />
                    <Route path="/session/verify/email" element={<VerifyEmail />} />
                    <Route path="/session/changeaccount" element={<ChangeAccount />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/jiraConnectApp" element={<JiraConnectRoutes />} />
                    {/* Already logged in, redirect to home */}
                    <Route path="/login" element={<Navigate replace to="/" />} />
                </Routes>
            </React.Fragment>
        );
    }
    
    return (
        <React.Fragment>
            <Routes>
                <Route path="*" element={<DashboardRoutes />} />
                <Route path="/admin/*" element={<AdminRoutes />} />
                <Route path="/stack/*" element={<StackRoutes />} />
                <Route path="/session/verify/email" element={<VerifyEmail />} />
                <Route path="/session/changeaccount" element={<ChangeAccount />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/jiraConnectApp" element={<JiraConnectRoutes />} />
                {/* Already logged in, redirect to home */}
                <Route path="/login" element={<Navigate replace to="/" />} />
            </Routes>
        </React.Fragment>
    );
});
