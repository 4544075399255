import internalRequest from "superagent";
import getSession from "util/getSession";
import { runInAction } from "mobx";
import * as Sentry from "@sentry/browser";

export enum ConflictType {
    Counter = 1,
    Version = 2
}

function handle(req, callback) {
    return new Promise((resolve, reject) => {
        req.end(function(err, res) {
            if (res && res.status === 401) {
                // TODO do something more graceful in case the user has unsaved data
                callback && runInAction(() => callback(err, res));
                getSession().clear();
                reject(err);
                return;
            }

            if (err) {
                Sentry.captureException(err);
                callback && runInAction(() => callback(err, res));
                reject(err);
                return;
            }

            // If a session object is returned with a 403, that means some sort
            // of requirements have failed and we need to do something.
            if (res?.status === 403 && res?.body?.requirements) {
                getSession().requirements.merge(res.body.requirements);
                reject(err);
                return;
            }

            if (res.body && res.body.result && !res.body.success) {
                // TODO This is deprecated ... removing the res.body.success bit in favor of just returning a 200
                callback && runInAction(() => callback("res.body.success != true", res));
                reject(err);
                return;
            }

            callback && runInAction(() => callback(err, res));
            resolve(res);
            return;
        });
    });
}

function addCommonHeaders(req) {
    // superagent doesn't set X-Requested-With header
    return req.set("X-Requested-With", "XMLHttpRequest");
}

function processRequest(req) {
    return addCommonHeaders(req);
}

// Adds a .respond() method to the superagent chain which starts a responseHandler chain.
function createRespondMethod(req) {
    req.handle = callback => handle(req, callback);
}

type Get<T> = {
    handle: (callback?: (err, res: { body: T; status: number }) => void) => Promise<{ body: T; status: number }>;
};

type Post<T> = {
    send: (any) => Post<T>;
    attach: (k: any, v?: any) => Post<T>;
    handle: (callback?: (err, res: { body: T; status: number }) => void) => Promise<{ body: T; status: number }>;
};

type Put<T> = {
    send: (any) => Put<T>;
    handle: (callback?: (err, res: { body: T; status: number }) => void) => Promise<{ body: T; status: number }>;
};

class RequestWrapper {
    get<T = any>(url: string): Get<T> {
        const req = internalRequest.get(...arguments);
        createRespondMethod(req);
        return processRequest(req);
    }

    post<T = any>(url: string): Post<T> {
        const req = internalRequest.post(...arguments);
        createRespondMethod(req);
        return processRequest(req);
    }

    put<T = any>(url: string): Put<T> {
        const req = internalRequest.put(...arguments);
        createRespondMethod(req);
        return processRequest(req);
    }

    del(url: string) {
        const req = internalRequest.del(...arguments);
        createRespondMethod(req);
        return processRequest(req);
    }
}

export default function() {
    return new RequestWrapper();
}
