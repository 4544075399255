import styled, { css } from "styled-components";
import React, { useEffect } from "react";
import classNames from "classnames";

export const PageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25em 0.5em;
    background-color: #1c1c1c;
    color: #fff;
    height: 40px;
    grid-column-start: 1;
    grid-column-end: 17;

    a {
        color: #fff;
    }

    && .dropdown.icon {
        margin-left: 0;
    }
`;

export const ToolTip = styled.span`
    display: block;
    width: 100px;
`;

export const HeaderLogoWrapper = styled.span`
    display: inline-flex;
    margin-left: 0.5em;

    a {
        display: inline-block;
        line-height: 1px;
    }
`;

export const StyledCard = styled.div<{$showScroll: string}>`
    .custom-card {
        display: flex;
        align-items: center;
    }

    ${props => 
        props.$showScroll == 'true' &&
        css`
            max-height: 500px;
            overflow: scroll;
        `};
`;

export const HeaderLinks = styled.span`
    display: flex;
    align-items: center;
    margin-right: 1em;
 
    > * {
        margin-left: 2em;
    }
`;

const Page = styled.div<{ $grid: boolean | undefined }>`
    display: grid;
    grid-template-columns: ${props => (props.$grid ? "repeat(16, 1fr)" : "1fr")};
    grid-template-rows: 40px 1fr;

    background-color: #fff;
    height: 100vh;

    .error-content {
        padding: 1em;
        grid-column-start: 1;
        grid-column-end: 17;
    }
`;

interface Props {
    children: any;
    grid?: boolean;
    title: string;
    className?: string;
    style?: React.CSSProperties;
}

export default ({ children, title, className, grid, style }: Props) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <Page $grid={grid || false} style={style} className={classNames("page", className)}>
            {children}
        </Page>
    );
};
