export enum ItemType {
    None = 0,
    Document = 1,
    Section = 2,
    Task = 3,
    PseudoTask = 4,
    Space = 5,
    External = 7,
    Action = 9,
    Tag = 11,
    Standard = 12,
    Control = 13,
    TaskStep = 15,
    Review = 16,
    Issue = 17,
    Questionnaire = 18,
    Question = 19,
    StandardSection = 20,
    Validation = 21,
    Client = 22
}

export enum ItemSubType {
    None = 0,
    // Documents
    Policy = 101,
    Procedure = 102,
    JobDescription = 103,
    Contract = 104,
    // Actions
    Recurring = 201,
    Event = 202,
    Declarative = 203,
    // Issues
    NonConformity = 301,
    OperationsException = 302,
    OpportunityForImprovement = 303,
    OverdueTask = 304,
    // Questionnaires
    GeneralQuestionnaire = 401,
    AuditQuestionnaire = 402,
    // Validations
    Official = 501,
    External = 502,
    Internal = 503,
    // External links
    Jira = 604,
    Link = 605
}

export enum DraftStatus {
    None = 0,
    IsPublished = 1,
    IsFirstDraft = 2,
    IsDraft = 3,
    IsArchived = 4,
    IsNewDraft = 5,
    IsDeleted = 6
}

export interface Item {
    id?: string;
    authorizingItemId?: string;
    name: string;
    type: ItemType;
    content: any;
    counter: number;
    version: number;
    reviewItemId?: string;
    authorizingIds?: string[];
    draftStatus?: DraftStatus;
}

export enum LinkType {
    Explains = 1,
    References = 2,
    Evidence = 3,
    Review = 4,
    Implements = 5,
    Embeds = 6,
    Remediates = 7,
    Answers = 8,
    Validates = 9
}

export enum LinkDirection {
    From,
    To
}

export interface DirectedLink {
    id?: string;
    itemId: string;
    localKey: number;
    type: LinkType;
    direction: LinkDirection;
    linkedItemId: string;
    linkedLocalKey: number;
    added: boolean;
    removed: boolean;
}
