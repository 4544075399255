import React from "react";
import ResetPasswordForm from "../components/ResetPasswordForm";
import PublicPage from "shared/components/content/PublicPage";

export default () => {
    return (
        <PublicPage title="Reset Password">
            <ResetPasswordForm />
        </PublicPage>
    );
};
