import getSession from "./getSession";

export default {
    getItem(key: string): any | null {
        const stringified = window.localStorage.getItem(key + "-" + getSession().entityUserId);
        if (!stringified || stringified === "undefined") {
            return null;
        }
        return JSON.parse(stringified);
    },

    setItem(key: string, value: any) {
        window.localStorage.setItem(key + "-" + getSession().entityUserId, JSON.stringify(value));
    },

    removeItem(key: string) {
        window.localStorage.removeItem(key + "-" + getSession().entityUserId);
    }
};
