import { observable, action, makeObservable } from "mobx";
import request from "util/request";
import SessionStore from "shared/models/SessionStore";
import sessionChangeRedirect from "util/sessionChangeRedirect";

export default class VerificationStore {
    token: string;
    session: SessionStore;
    @observable failed = false;

    constructor(token: string, session: SessionStore) {
        makeObservable(this);
        this.token = token;
        this.session = session;
    }

    verify() {
        return request()
            .post("/api/verification")
            .send({ verificationType: 1 /* Email */, token: this.token })
            .handle(err => {
                if (!err) {
                    sessionChangeRedirect();
                }
            })
            .catch(action(() => (this.failed = true)));
    }
}
