import { observable, action, makeObservable } from "mobx";
import request from "util/request";
import { toast } from "react-toastify";
import LoadScript from "util/loadScript";
import { JiraIntegrationStatus } from "shared/models/JiraIntegration";

declare global {
    interface Window {
        AP: any;
    }
}

export default class JiraConnectStore {
    @observable integrationStatus?: JiraIntegrationStatus = -1;
    @observable failed?: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    callInitialServices(): Promise<void> {
        return new Promise(resolve => {
            this.setJiraStatus();
            resolve();
        });
    }

    @action.bound
    load() {
        // Need to load up the Atlassian Javascript API
        // if we are loaded from within the Jira Connect App
        if (!window.AP) {
            let scriptLoader = new LoadScript({
                src: "connect-cdn.atl-paas.net/all.js",
                global: "AP"
            });
            //<script src="https://connect-cdn.atl-paas.net/all.js" data-options="sizeToParent:true;"></script>
            return scriptLoader.load().then(this.callInitialServices);
        } else {
            return this.callInitialServices();
        }
    }

    @action.bound
    public setJiraStatus(): void {
        window.AP.context.getToken(jwt => {
            return request()
                .get(`/api/jiraconnectapp/status?jwt=${jwt}`)
                .handle((err, res) => {
                    if (err) {
                        toast.error("Oops, there was an error getting your Jira integration status.");
                        return;
                    }

                    this.integrationStatus = res.body;
                });
        });
    }
}
