import React from "react";
import TwoFactorSetupStore from "../models/TwoFactorSetupStore";
import TwoFactorMethodSelector from "../components/TwoFactorMethodSelector";
import Page from "shared/components/content/Page";
import TwoFactorForm from "../components/TwoFactorForm";
import { Container, Message, Segment } from "semantic-ui-react";
import { ContentLoader, useObservingView } from "util/view-hooks";
import { Logo } from "session/Styles";
import FloatingLogoutButton from "session/components/FloatingLogoutButton";

export default () =>
    useObservingView(
        () => new TwoFactorSetupStore(),
        store => (
            <Page title="Two Factor Authentication">
                <Container text style={{ padding: "2em 0" }}>
                    <FloatingLogoutButton />
                    {/* TODO: Replace this with real logo */}
                    <Logo as="h1" textAlign="center">
                        aberrant
                    </Logo>
                    <Message attached info>
                        <Message.Header>Set Up Two Factor Application</Message.Header>
                    </Message>
                    <Segment attached="bottom">
                        {store.loader && <ContentLoader loader={store.loader} errorMessage="Error loading QR code" />}
                        {store.qrCode && (
                            <img
                                alt="Two Factor Setup QR Code"
                                style={{ margin: "0 auto", display: "block", maxWidth: "200px" }}
                                src={store.qrCode}
                            />
                        )}
                        {store.manualCode && (
                            <Message>
                                <p>
                                    To get your token, scan the QR code or enter the following into any two factor
                                    application:
                                </p>
                                <p>
                                    <strong>{store.manualCode}</strong>
                                </p>
                            </Message>
                        )}
                    </Segment>
                    {!store.isMethodSelectorVisible && (
                        <Segment>
                            <p>
                                Or{" "}
                                <button className="ui link" onClick={store.showMethodSelector}>
                                    select an alternative method
                                </button>{" "}
                                to receive your two factor token.
                            </p>
                        </Segment>
                    )}
                    {!store.codeSent &&
                        store.isMethodSelectorVisible &&
                        store.methodOptions &&
                        store.methodOptions.length > 0 && (
                            <>
                                <TwoFactorMethodSelector store={store} />
                            </>
                        )}
                    <TwoFactorForm isSetup={true} />
                </Container>
            </Page>
        )
    );
