import React from "react";

export default class Privacy extends React.Component {
    render() {
        return (
            <div style={{ margin: 40 }}>
                <h1>Aberrant's Terms of Use</h1>
                <h2>Privacy</h2>
                <p>
                    Your privacy is important to us.  For information regarding our use of information collected or
                    provided through the Site, including information regarding non-public information we collect through
                    use of the Site, please take the time to review our Privacy Policy.
                </p>
                <h2>Rules of Conduct</h2>
                <p>
                    In using the Site, you agree to comply with these Terms of Use, Aberrant rules and policies, and all
                    applicable laws, rules and regulations.  You further agree that you will not:
                </p>
                <ul>
                    <li>
                        Interrupt or attempt to interrupt the operation of the Site or the servers or networks used to
                        make the Site available;
                    </li>
                    <li>
                        Transmit or otherwise make available in connection with the Site any virus, worm, Trojan horse,
                        Easter egg, time bomb, spyware, or other computer code, file or program that is harmful or
                        invasive or may or is intended to damage or hijack the operation of, or to monitor the use of,
                        any hardware, software or equipment;
                    </li>
                    <li>
                        Restrict or inhibit any other person from using the Site (including without limitation by
                        hacking or defacing any portion of the Site);
                    </li>
                    <li>
                        Attempt to gain unauthorized access to any accounts, computer systems or networks through
                        hacking, phishing, password mining or any other means;
                    </li>
                    <li>
                        Impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with
                        a person or entity;
                    </li>
                    <li>
                        Reverse engineer, reverse assemble, decompile, create derivative works, modify, or otherwise
                        attempt to derive the source code of the Site;
                    </li>
                    <li>
                        Use any material or information in a manner that infringes any copyright, trademark, patent,
                        trade secret or other proprietary right of any party; or
                    </li>
                    <li>
                        Collect personal information about others, including email addresses or other personal
                        information.
                    </li>
                </ul>
                <h2>No Advice</h2>
                <p>
                    Nothing contained on the Site is intended to constitute tax or financial advice. The information and
                    materials on the Site are not intended as an offer or solicitation for the purchase of stock, any
                    other security or any financial instrument of Aberrant, its affiliates, or any other issuer or
                    company. No content or comments made by Aberrant, its employees, agents or any other user should be
                    understood or used as the basis for any investment or financial decision, nor should they be
                    construed as advice, endorsements or recommendations.
                </p>
                <p>
                    In no event shall any reference to any third party or third party product or service be construed as
                    an approval, guarantee or endorsement by us of that third party or of any product or service offered
                    by that third party.
                </p>
                <h2>Links</h2>
                <p>
                    Links from the Site to an external site, unaffiliated with Aberrant, may be provided for users’
                    convenience only.  We do not control or review these sites nor does the provision of any link imply
                    an endorsement or association of such non- Aberrant sites.  We are not responsible for and make no
                    representation or warranty regarding the contents, completeness or accuracy or security of any
                    materials or information on such sites.  If you decide to access such sites, you do so at your own
                    risks.
                </p>
                <h2>Copyright and Trademark Notices and Intellectual Property Rights of Aberrant</h2>
                <p>
                    We and our licensors and suppliers own the information and materials made available through the
                    Site.  Such information and materials may be protected by copyright, trademark, patent and/or other
                    proprietary rights and laws.  Except as otherwise expressly stated herein, copyrighted, trademarked
                    materials or other intellectual property on this Site (including without limitation, images of
                    people and places) may not be used, copied, transmitted, displayed, performed, distributed (for
                    compensation or otherwise), licensed, altered, framed, stored for subsequent use, removed, reverse
                    engineered, modified or otherwise used in whole or in part in any manner without our prior written
                    consent, except to the extent permitted by applicable law, and then, only with notices of our
                    proprietary rights; provided that you may download information and print out hard copies for your
                    personal use, so long as you do not remove any copyright or other notice as may be contained in such
                    information, as downloaded. Please be advised that any violation of such copyright, trademark or
                    other intellectual property rights or any privacy or communication laws is your sole responsibility.
                    Aberrant will aggressively enforce its intellectual property rights to the fullest extent of the
                    law, including without limitation, the seeking of criminal prosecution.
                </p>
                <p>
                    Aberrant® and the Aberrant logo are trademarks of Aberrant, Inc.  Aberrant and its affiliates also
                    claim rights in certain other trademarks and service marks contained on the Site.  Other featured
                    words or symbols on the Site, used to identify the source of goods and services, may be trademarks
                    of their respective owners.
                </p>
                <h2>Disclaimer</h2>
                <p>
                    The information and materials contained on the Site are for information purposes only. Use of the
                    Site is at your sole risk. We use reasonable efforts to include reliable information and materials,
                    but Aberrant makes NO WARRANTIES OR REPRESENTATIONS AS TO THE ACCURACY, COMPLETENESS AND TIMELINESS
                    OF SUCH INFORMATION AND MATERIALS AND EXPRESSLY DISCLAIMS ANY LIABILITY FOR ANY ERRORS OR OMISSION
                    CONTAINED IN THE SITE.
                </p>
                <p>
                    Neither Aberrant nor any other party involved in creating, producing, or delivering the Site is
                    liable for any direct, incidental, consequential, indirect, or punitive damages arising out of
                    access to, or use of, the Site. Without limiting the foregoing, everything on the Site is provided
                    “AS IS,” “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED, IMPLIED OR STATUTORY,
                    INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, TITLE,
                    MERCANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. FURTHERMORE, ABERRANT WILL NOT BE LIABLE FOR
                    ANY DELAY, DIFFICULTY IN USE, COMPUTER VIRUSES, MALICIOUS CODE, OR OTHER DEFECT IN THE SITE, ANY
                    INCOMPATIBILITY BETWEEN THE SITE AND YOUR FILES AND YOUR BROWSER OR OTHER SERVICES ACCESSING
                    PROGRAM, OR ANY OTHER PROBLEMS EXPERIENCED BY YOU DUE TO YOUR ACCESS TO, USE OF, OR BROWSING OF THE
                    SITE OR DOWNLOADING OF ANY INFORMATION OR MATERIALS.
                </p>
                <p>
                    UNDER NO CIRCUMSTANCES WILL ABERRANT BE LIABLE FOR ANY LOST PROFITS, LOST OPPORTUNITY OR ANY
                    INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES ARISING OUT OF ANY USE
                    OR INABILITY TO USE THE SITE OR ANY PORTION THEREOF, REGARDLESS OF WHETHER ABERRANT HAS BEEN
                    APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURING AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN
                    CONTRACT, WARRANTY, TORT, STRICT LIABILITY OR OTHERWISE.
                </p>
                <p>
                    The information and materials contained in the Site are subject to change without notice. We may
                    discontinue or make changes in the information, materials, services or products which may be
                    described or provided for on the Site at any time without prior notice to you and without any
                    liability to you. Any dated information is published as of its date only, and we do not undertake
                    any obligation or responsibility to update or amend any such information.
                </p>
                <h2>Limitation of Access</h2>
                <p>
                    We may shut down this Site, or limit or deny access to all or part of the Site, or the Site may from
                    time to time be unavailable, delayed or slowed at any time, for any reason, without notice, and
                    without liability to you.
                </p>
                <h2>Other Agreements</h2>
                <p>
                    These Terms of Use do not nullify, and are in addition to, any other terms and conditions contained
                    in other agreement(s) between you and Aberrant, including without limitation, any agreement
                    regarding your use of any webpages, websites or social media sites maintained by us.  In the event
                    of a conflict, the additional terms and conditions will govern those sections or pages.  In
                    addition, certain portions or pages of the Site, or other webpages, websites or social media sites
                    are subject to additional disclosures and disclaimers.  In the event of conflict between those
                    disclosures and disclaimers and these Terms of Use, the additional disclosures and disclaimers will
                    govern those portions, pages or sites.  Termination or modification of these Terms of Use will not
                    affect, or result in the termination or modification of, any such other agreements or disclaimers.
                </p>
                <h2>Governing Law</h2>
                <p>
                    These Terms of Use will be interpreted, governed and enforced under the laws of the State of
                    Delaware, without giving effect to its choice or conflicts of law provisions.  The Site is not
                    intended for distribution to, or use by, any person or entity in any jurisdiction or country where
                    such distribution or use would be contrary to local law or regulation.  In the event there is deemed
                    to be any information on the Site regarding services and products then such information is
                    applicable only in jurisdictions where such services and products may lawfully be offered for sale
                    and is void where prohibited by law.  If you access the Site from outside of the United States, you
                    are responsible for compliance with local, national and international laws.
                </p>
                <h2>Severability</h2>
                <p>
                    If any term or provision of these Terms of Use is held unenforceable, the validity or enforceability
                    of the remaining terms and provisions will not be affected, and the unenforceable provision will be
                    replaced with an enforceable provision that comes closest to the intention underlying the
                    unenforceable term or provision.
                </p>
                <h2>Headings</h2>
                <p>
                    The headings inserted in these Terms of Use are inserted for convenience and identification only and
                    are not intended to describe, interpret, define or limit the scope or intent of this agreement or
                    any clause hereof.
                </p>
                <h2>Amendments</h2>
                <p>
                    We reserve the right to change these Terms of Use at any time and without prior notice, by posting
                    revisions on this Site. You are bound by any such revisions as of the time of such posting and you
                    should therefore periodically visit this page to review the current Terms of Use.  Your simultaneous
                    or subsequent use of the Site constitutes your agreement to be bound by the revised Terms of Use.
                </p>
                <h2>Questions & Complaints</h2>
                <p>
                    If you have questions, would like to report unethical behavior, or if you would like to make a
                    complaint, please contact us at <a href="mailto:complaints@aberrant.com">complaints@aberrant.com</a>
                    .
                </p>
            </div>
        );
    }
}
