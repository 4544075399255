import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { TextArea } from "semantic-ui-react";
import request from "util/request";

export default observer(() => {
    const [token, setToken] = useState<string>();

    useEffect(() => {
        request()
            .get<string>(`/api/jira/token`)
            .handle((err, res) => {
                setToken((res as any).text);
            });
    }, []);

    if (!token) {
        return null;
    }

    return (
        <>
            <div>Use the following token to configure your Jira Connect app:</div>
            <TextArea style={{ fontFamily: "monospace", marginTop: "1em" }} readOnly value={token} />
        </>
    );
});
