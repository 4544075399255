import React from "react";
import { Button, Dropdown } from "semantic-ui-react";
import PhoneInput from "react-phone-number-input/input";
import { useForm, Controller } from "react-hook-form";
import request from "util/request";
import { forEach } from "lodash";
import { toast } from "react-toastify";
import { Form, Note } from "registration/Styles";
import { Link } from "react-router-dom";
import sessionChangeRedirect from "util/sessionChangeRedirect";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { PasswordChecklist } from "shared/components/PasswordChecklist";

//1 - US / Canada, 2 - all other
enum PhoneCountry {
    UsOrCanada = 1,
    Other = 2
}

interface AcceptInvitationVm {
    token: string;
    registrationUrl: string;
    user: {
        password: string;
        reEnterPassword: string;
        phoneCountry: PhoneCountry;
        phone: string;
    };
}

export default function AcceptInvitationForm({ user, token, registrationUrl }: AcceptInvitationVm) {
    const {
        handleSubmit,
        control,
        getValues,
        setError,
        watch,
        formState: { isSubmitting, errors }
    } = useForm<AcceptInvitationVm>({
        defaultValues: { user },
        mode: "onBlur"
    });

    const phoneCountryOptions = [
        { key: PhoneCountry.UsOrCanada, text: "US / Canadian Phone Number", value: PhoneCountry.UsOrCanada },
        { key: PhoneCountry.Other, text: "Phone Number outside of the US or Canada", value: PhoneCountry.Other }
    ] as const;
    const selectedPhoneCountry = watch("user.phoneCountry", phoneCountryOptions[0].value);

    const password = watch("user.password");
    const confirmPassword = watch("user.reEnterPassword");

    const onSubmit = (data: AcceptInvitationVm) => {
        return request()
            .post(registrationUrl)
            .send(Object.assign({ token }, data))
            .handle((err, res) => {
                if (err) {
                    if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof AcceptInvitationVm, { message });
                        });
                    } else {
                        toast.error("There was an error signing up. Please try again later.");
                    }
                    return;
                }

                sessionChangeRedirect();
            })
            .catch((err) => {
                // don't bubble these errors to sentry
                if (err.status === 400) {
                } else {
                    throw err;
                }
            });
    };

    return (
        <Form role="form" onSubmit={handleSubmit(onSubmit)} className="registration">
            <Controller
                name="user.password"
                render={({ field: { value, onChange } }) => {
                    return (
                        <Form.Input
                            value={value}
                            onChange={onChange}
                            label="Password"
                            type="password"
                            id="password"
                            error={errors.user?.password && { content: errors.user.password.message }}
                        />
                    );
                }}
                control={control}
                defaultValue=""
                rules={{
                    required: "Password is required",
                    minLength: {
                        value: 10,
                        message: "Password must be at least 10 characters"
                    },
                    pattern: {
                        value: new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{10,}$"),
                        message: "Password must have at least one numeric, alpha, uppercase or non-alpha character+"
                    }
                }}
            />
            <Controller
                name="user.reEnterPassword"
                control={control}
                defaultValue=""
                rules={{
                    required: "Re-entering the password is required",
                    validate: (value) => value === getValues("user.password") || "Passwords do not match"
                }}
                render={({ field: { value, onChange } }) => (
                    <Form.Input
                        value={value}
                        onChange={onChange}
                        label="Re-enter Password"
                        type="password"
                        id="reEnterPassword"
                        error={errors.user?.reEnterPassword && { content: errors.user.reEnterPassword.message }}
                    />
                )}
            />
            <PasswordChecklist value={password ?? ""} valueAgain={confirmPassword ?? ""} />
            <Controller
                name="user.phoneCountry"
                control={control}
                defaultValue={phoneCountryOptions[0]?.value}
                render={({ field: { value, onChange } }) => (
                    <Form.Field
                        control={Dropdown}
                        label="Select phone country"
                        id="phoneCountry"
                        options={phoneCountryOptions}
                        value={value}
                        onChange={(e, { value }) => onChange(value)}
                        selection
                    />
                )}
            />
            <Controller
                name="user.phone"
                render={({ field: { value, onChange } }) => {
                    const usOrCanadaSelected = selectedPhoneCountry === PhoneCountry.UsOrCanada;
                    return (
                        <PhoneInput
                            inputComponent={Form.Input}
                            value={value}
                            country={usOrCanadaSelected ? "US" : undefined}
                            international={usOrCanadaSelected || undefined}
                            withCountryCallingCode={usOrCanadaSelected || undefined}
                            onChange={onChange}
                            label="Phone"
                            id="phone"
                            error={errors.user?.phone && { content: errors.user.phone.message }}
                        />
                    );
                }}
                defaultValue=""
                control={control}
                rules={{
                    validate: (value) => {
                        const isRequired = selectedPhoneCountry === PhoneCountry.UsOrCanada;
                        if (!value?.length) {
                            return isRequired ? "Phone number is required" : undefined;
                        }
                        const isValid = isPossiblePhoneNumber(value);
                        if (!isValid) {
                            return "Invalid phone number";
                        }
                    }
                }}
            />

            <Button primary fluid size="big" type="submit" loading={isSubmitting} role="submit">
                Sign Up
            </Button>
            <Note>
                By signing up, you are agreeing to our <Link to="/privacy">Privacy Policy</Link> and{" "}
                <Link to="/terms">Terms of Use</Link>.
            </Note>
        </Form>
    );
}
