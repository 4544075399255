import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Grid, Header, Message } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { Box, Container, Contact } from "../Styles";
import { Logo } from "session/Styles";
import AcceptInvitationForm from "../components/AcceptInvitationForm";
import { ViewLoader } from "util/view-hooks";
import { useLoader } from "util/Loader";
import request from "util/request";
import PublicPage from "shared/components/content/PublicPage";
import { contexts } from "AppProvider";
import PrivatePage from "shared/components/content/PrivatePage";

const AcceptInvitation = () => {
    const params = new URLSearchParams(useLocation().search);
    const token = params.get("token");
    const loader = useLoader(false, { immediate: true });
    const [formData, setFormData] = useState<any>();
    const session = useContext(contexts).session;
    const location = useLocation();

    useEffect(() => {
        loader.load(
            request()
                .get(`/api/registration/user/form?token=${token}`)
                .handle((err, res) => {
                    if (err) return;
                    setFormData(res.body);
                })
        );
    }, [loader]);

    if (loader.loading) {
        return <ViewLoader />;
    }

    if (loader.error) {
        return (
            <Container>
                <Message error>There was an error with your invitation.</Message>
            </Container>
        );
    }

    const returnUrl = params.get("return") ?? encodeURIComponent(location.pathname + location.search);

    return (
        <PublicPage title="Register" hideHeader hideFooter>
            <Container>
                {/* TODO: Replace this with real logo */}
                <Logo as="h1" textAlign="center">
                    aberrant
                </Logo>

                <Box>
                    <Grid celled="internally">
                        <Grid.Column width={8}>
                            <AcceptInvitationForm {...formData} registrationUrl="/api/registration/user" />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header align="center" as="h3">
                                Already have an account?
                                <Link style={{ marginLeft: 4 }} to={`/login?return=${returnUrl}`}>
                                    Log In
                                </Link>
                            </Header>
                            <Contact>
                                Contact us: <a href="mailto:questions@aberrant.io">questions@aberrant.io</a>
                            </Contact>
                        </Grid.Column>
                    </Grid>
                </Box>
            </Container>
        </PublicPage>
    );
};

export default observer(AcceptInvitation);
