import { isNumber } from "lodash";
import { Schema } from "prosemirror-model";
import { tableNodes } from "prosemirror-tables";
import createLocalKey from "./createLocalKey";

const pDOM = ["p", 0],
    blockquoteDOM = ["blockquote", 0],
    hrDOM = ["hr"],
    preDOM = ["pre", ["code", 0]],
    brDOM = ["br"],
    olDOM = ["ol", 0],
    ulDOM = ["ul", 0],
    liDOM = ["li", 0];

const getHeadingAttrs = (level, dom) => {
    let localKey = parseInt(dom.getAttribute("data-local-key"), 10);
    if (!isNumber(localKey)) {
        localKey = createLocalKey();
    }
    return {
        level,
        localKey,
        links: JSON.parse(dom.getAttribute("data-links")) || [],
        isAppendix: JSON.parse(dom.getAttribute("data-appendix")),
        overrideDisplayNumber: JSON.parse(dom.getAttribute("data-override-number"))
    };
};

export const nodes: any = {
    // The top level document node.
    doc: {
        content: "section+"
    },

    // The top level description editor node.
    description: {
        content: "block+"
    },

    section: {
        attrs: { hidden: { default: false } },
        content: "heading block*",
        parseDOM: [
            {
                tag: "section",
                getAttrs(dom) {
                    return {
                        hidden: dom.hasAttribute("data-hidden")
                    };
                }
            }
        ],
        toDOM(node) {
            const attrs = {};
            if (node.attrs.hidden) {
                attrs["data-hidden"] = true;
            }
            return ["section", attrs, 0];
        }
    },

    imperative: {
        attrs: {
            localKey: { default: 0 },
            value: { default: "" },
            placeholder: { default: "" },
            guidance: { default: "" }
        },
        group: "inline",
        inline: true,
        content: "text*",
        atom: true,

        toDOM: node => [
            "span",
            {
                "data-value": node.attrs.value,
                "data-placeholder": node.attrs.placeholder,
                "data-guidance": node.attrs.guidance,
                "data-local-key": node.attrs.localKey,
                class: "imperative-node"
            },
            0
        ],
        parseDOM: [
            {
                tag: "span.imperative-node",
                getAttrs: dom => {
                    return {
                        value: dom.getAttribute("data-value"),
                        placeholder: dom.getAttribute("data-placeholder"),
                        guidance: dom.getAttribute("data-guidance"),
                        localKey: parseInt(dom.getAttribute("data-local-key"), 10)
                    };
                }
            }
        ]
    },

    paragraph: {
        content: "inline*",
        group: "block",
        parseDOM: [{ tag: "p" }],
        toDOM() {
            return pDOM;
        }
    },

    // A blockquote (`<blockquote>`) wrapping one or more blocks.
    blockquote: {
        content: "block+",
        group: "block",
        defining: true,
        parseDOM: [{ tag: "blockquote" }],
        toDOM() {
            return blockquoteDOM;
        }
    },

    horizontal_rule: {
        group: "block",
        parseDOM: [{ tag: "hr" }],
        toDOM() {
            return hrDOM;
        }
    },

    // A heading textblock, with a `level` attribute that
    // should hold the number 1 to 6. Parsed and serialized as `<h1>` to
    // `<h6>` elements.
    heading: {
        attrs: {
            isAppendix: { default: false },
            level: { default: 1 },
            localKey: { default: 0 },
            links: { default: [] },
            overrideDisplayNumber: { default: "" }
        },
        content: "inline*",
        group: "block",
        defining: true,
        parseDOM: [
            {
                tag: "h1",
                getAttrs: dom => getHeadingAttrs(1, dom)
            },
            {
                tag: "h2",
                getAttrs: dom => getHeadingAttrs(2, dom)
            },
            {
                tag: "h3",
                getAttrs: dom => getHeadingAttrs(3, dom)
            },
            {
                tag: "h4",
                getAttrs: dom => getHeadingAttrs(4, dom)
            },
            {
                tag: "h5",
                getAttrs: dom => getHeadingAttrs(5, dom)
            },
            {
                tag: "h6",
                getAttrs: dom => getHeadingAttrs(6, dom)
            }
        ],
        toDOM(node) {
            return [
                "h" + node.attrs.level,
                {
                    class: "heading",
                    "data-local-key": node.attrs.localKey,
                    "data-links": JSON.stringify(node.attrs.links),
                    "data-appendix": node.attrs.isAppendix,
                    "data-override-number": node.attrs.overrideDisplayNumber
                        ? JSON.stringify(node.attrs.overrideDisplayNumber)
                        : undefined
                },
                0
            ];
        }
    },

    // A code listing. Disallows marks or non-text inline
    // nodes by default. Represented as a `<pre>` element with a
    // `<code>` element inside of it.
    code_block: {
        content: "text*",
        marks: "",
        group: "block",
        code: true,
        defining: true,
        parseDOM: [{ tag: "pre", preserveWhitespace: "full" }],
        toDOM() {
            return preDOM;
        }
    },

    // The text node.
    text: {
        group: "inline"
    },

    image: {
        inline: true,
        attrs: {
            src: {},
            alt: { default: null },
            title: { default: null },
            width: { default: "400px" }
        },
        group: "inline",
        draggable: true,
        parseDOM: [
            {
                tag: "img[src][width]",
                getAttrs(dom) {
                    return {
                        src: dom.getAttribute("src"),
                        title: dom.getAttribute("title"),
                        alt: dom.getAttribute("alt"),
                        width: dom.getAttribute("width")
                    };
                }
            }
        ],
        toDOM(node) {
            let { src, alt, title, width } = node.attrs;
            return ["img", { src, alt, title, width, style: `width: ${width};` }];
        }
    },

    hard_break: {
        inline: true,
        group: "inline",
        selectable: false,
        parseDOM: [{ tag: "br" }],
        toDOM() {
            return brDOM;
        }
    },

    ordered_list: {
        content: "list_item+",
        group: "block",
        attrs: { order: { default: 1 } },
        parseDOM: [
            {
                tag: "ol",
                getAttrs(dom) {
                    return { order: dom.hasAttribute("start") ? +dom.getAttribute("start") : 1 };
                }
            }
        ],
        toDOM(node) {
            return node.attrs.order === 1 ? olDOM : ["ol", { start: node.attrs.order }, 0];
        }
    },

    bullet_list: {
        content: "list_item+",
        group: "block",
        parseDOM: [{ tag: "ul" }],
        toDOM() {
            return ulDOM;
        }
    },

    list_item: {
        content: "paragraph block*",
        parseDOM: [{ tag: "li" }],
        toDOM() {
            return liDOM;
        },
        defining: true
    },

    todo_list: {
        content: "todo_item+",
        group: "block",
        toDOM(node) {
            return [
                "ul",
                {
                    "data-type": "todo_list"
                },
                0
            ];
        },
        parseDOM: [
            {
                priority: 51, // Needs higher priority than other nodes that use a "ul" tag
                tag: '[data-type="todo_list"]'
            }
        ]
    },

    todo_item: {
        attrs: {
            checked: { default: false }
        },
        content: "paragraph block*",
        defining: true,
        toDOM(node) {
            return ["li", { "data-checked": node.attrs.checked.toString() }, 0];
        },
        parseDOM: [
            {
                priority: 51, // Needs higher priority than other nodes that use a "li" tag
                tag: 'li[type="todo-item"]',
                getAttrs(dom) {
                    return {
                        checked: dom.getAttribute("data-checked") === "true"
                    };
                }
            }
        ]
    }
};

const emDOM = ["em", 0],
    strongDOM = ["strong", 0],
    codeDOM = ["code", 0];

export const marks: any = {
    // Showing deleted content on diffs
    deleted: {
        toDOM(mark, inline) {
            return ["span", { class: "deleted" }];
        }
    },
    inserted: {
        toDOM(mark, inline) {
            return ["span", { class: "inserted" }];
        }
    },
    highlight: {
        toDOM(mark, inline) {
            return ["span", { class: "highlight" }];
        }
    },
    height_placeholder: {
        toDOM(mark, inline) {
            return ["div", { class: "height_placeholder" }];
        }
    },
    link: {
        attrs: {
            href: {},
            title: { default: null }
        },
        inclusive: false,
        parseDOM: [
            {
                tag: "a[href]",
                getAttrs(dom) {
                    return { href: dom.getAttribute("href"), title: dom.getAttribute("title") };
                }
            }
        ],
        toDOM(node) {
            let { href, title } = node.attrs;
            return ["a", { href, title }, 0];
        }
    },

    internal_link: {
        attrs: {
            itemId: {},
            localKey: {}
        },
        excludes: "",
        inclusive: false,
        parseDOM: [
            {
                tag: "span[data-item-id][data-local-key]",
                getAttrs(dom) {
                    return { itemId: dom.getAttribute("data-item-id"), localKey: dom.getAttribute("data-local-key") };
                }
            }
        ],
        toDOM(node) {
            return [
                "span",
                {
                    "data-item-id": node.attrs.itemId,
                    "data-local-key": node.attrs.localKey,
                    style: "color:#0ac;text-decoration:underline;"
                },
                0
            ];
        }
    },

    tag: {
        attrs: {
            itemId: {}
        },
        excludes: "",
        inclusive: false,
        parseDOM: [
            {
                tag: "span[data-tag-id]",
                getAttrs(dom) {
                    return { itemId: dom.getAttribute("data-tag-id") };
                }
            }
        ],
        toDOM(node) {
            return ["span", { "data-tag-id": node.attrs.itemId, style: "background-color:#dfd;" }, 0];
        }
    },

    em: {
        parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
        toDOM() {
            return emDOM;
        }
    },

    strong: {
        parseDOM: [
            { tag: "strong" },
            // This works around a Google Docs misbehavior where
            // pasted content will be inexplicably wrapped in `<b>`
            // tags with a font-weight normal.
            { tag: "b", getAttrs: node => node.style.fontWeight !== "normal" && null },
            { style: "font-weight", getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null }
        ],
        toDOM() {
            return strongDOM;
        }
    },

    code: {
        parseDOM: [{ tag: "code" }],
        toDOM() {
            return codeDOM;
        }
    }
};

export const schema = new Schema({
    nodes: Object.assign(
        nodes,
        tableNodes({
            tableGroup: "block",
            cellContent: "block+",
            cellAttributes: {
                background: {
                    default: null,
                    getFromDOM(dom: any) {
                        return dom.style.backgroundColor || null;
                    },
                    setDOMAttr(value, attrs) {
                        if (value) attrs.style = (attrs.style || "") + `background-color: ${value};`;
                    }
                }
            }
        })
    ),
    marks
});
