export enum JiraIntegrationStatus {
    NOT_QUERIED = -1,
    NOT_INSTALLED = 0,
    WAITING_FOR_CONNECTION = 1,
    CONNECTED = 2
}

export type JiraIssue = {
    name: string;
    id: string;
    iconUrl: string;
};

export type JiraAvatarUrls = {
    "48x48": string;
};

export type JiraProject = {
    issuetypes: JiraIssue[];
    id: string;
    key: string;
    avatarUrls: JiraAvatarUrls;
};

export type JiraIssueStatus = {
    id: string;
    name: string;
    iconUrl: string;
};
