import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";

export default () => {
    const currentYear = new Date().getFullYear();
    return (
        <Footer>
            <div>
                Copyright &copy; {currentYear} &bull; <Link to="/privacy">Privacy Policy</Link> &bull;{" "}
                <Link to="/terms">Terms of Use</Link>{" "}
            </div>
        </Footer>
    );
};
