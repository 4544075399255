import { WorkflowStatusType } from "shared/models/workflow/Workflow";

export const statusNameMap = {
    [WorkflowStatusType.Unscoped]: "Unscoped",
    [WorkflowStatusType.InScope]: "In Scope",
    [WorkflowStatusType.Ready]: "Done",
    [WorkflowStatusType.OutOfScope]: "Out of Scope",
    [WorkflowStatusType.InProgress]: "In Progress",
    [WorkflowStatusType.Open]: "Not Started",
    [WorkflowStatusType.ReviewRequested]: "Needs Review",
    [WorkflowStatusType.Approved]: "Approved",
    [WorkflowStatusType.Rejected]: "Rejected",
    [WorkflowStatusType.ChangesRequested]: "Changes Requested",
    [WorkflowStatusType.Closed]: "Won't Do",
    [WorkflowStatusType.CloseRequested]: "Close Requested",
    [WorkflowStatusType.Incomplete]: "Incomplete",
    [WorkflowStatusType.EvidenceAdded]: "Evidence Added",
    [WorkflowStatusType.ExplanationAdded]: "Explanation Added",
    [WorkflowStatusType.ExplanationApproved]: "Explanation Approved",
    [WorkflowStatusType.NoEvidenceRequired]: "Evidence Added",
    [WorkflowStatusType.Published]: "Published",
    [WorkflowStatusType.Future]: "Future",
    [WorkflowStatusType.IssueOpen]: "Open",
    [WorkflowStatusType.IssueRiskAccepted]: "Risk Accepted",
    [WorkflowStatusType.IssueResolved]: "Done",
    [WorkflowStatusType.IssueReadyToTest]: "Ready to Test",
    [WorkflowStatusType.Implemented]: "Implemented",
    [WorkflowStatusType.IncludedInAudit]: "Included",
    [WorkflowStatusType.ExcludedFromAudit]: "Excluded",
    [WorkflowStatusType.Validated]: "Validated",
    [WorkflowStatusType.NotValidated]: "Not Validated"
};

export const statusColorMap = {
    [WorkflowStatusType.Unscoped]: "#aaa",
    [WorkflowStatusType.InScope]: "#44d",
    [WorkflowStatusType.Open]: "#aaa",
    [WorkflowStatusType.InProgress]: "#44d",
    [WorkflowStatusType.Ready]: "#4c4",
    [WorkflowStatusType.OutOfScope]: "#333",
    [WorkflowStatusType.ReviewRequested]: "rgb(122,0,191)",
    [WorkflowStatusType.Published]: "rgb(122,0,191)",
    [WorkflowStatusType.Approved]: "#4c4",
    [WorkflowStatusType.Rejected]: "#d44",
    [WorkflowStatusType.ChangesRequested]: "#dd4",
    [WorkflowStatusType.Closed]: "#333",
    [WorkflowStatusType.CloseRequested]: "#dd4",
    [WorkflowStatusType.Incomplete]: "#aaa",
    [WorkflowStatusType.EvidenceAdded]: "#4c4",
    [WorkflowStatusType.ExplanationAdded]: "#d44",
    [WorkflowStatusType.ExplanationApproved]: "#f96",
    [WorkflowStatusType.NoEvidenceRequired]: "#4c4",
    [WorkflowStatusType.Future]: "#f90",
    [WorkflowStatusType.IssueOpen]: "#d44",
    [WorkflowStatusType.IssueRiskAccepted]: "#44d",
    [WorkflowStatusType.IssueResolved]: "#4c4",
    [WorkflowStatusType.IssueReadyToTest]: "rgb(122,0,191)",
    [WorkflowStatusType.Implemented]: "#4c4",
    [WorkflowStatusType.IncludedInAudit]: "#4c4",
    [WorkflowStatusType.ExcludedFromAudit]: "#aaa",
    [WorkflowStatusType.Validated]: "#4c4",
    [WorkflowStatusType.NotValidated]: "#aaa"
};
