import React from "react";
import Page, { HeaderLinks, HeaderLogoWrapper, PageHeader } from "./Page";
import PublicFooter from "./PublicFooter";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "assets/logo.png";

const PublicPageContent = styled.div`
    padding-top: 2em;
    grid-column-start: 1;
    grid-column-end: 17;
`;

interface Props {
    children: any;
    title: string;
    className?: string;
    hideHeader?: boolean;
    hideFooter?: boolean;
}

export default (props: Props) => {
    const { children, title, className, hideHeader, hideFooter } = props;
    return (
        <Page style={{ height: "auto", minHeight: "100vh" }} className={className} title={title}>
            {!hideHeader && (
                <PageHeader className="page-header">
                    <HeaderLogoWrapper>
                        <Link to="/">
                            <img alt="Aberrant Logo" src={logo} width="36" />
                        </Link>
                    </HeaderLogoWrapper>
                    <HeaderLinks>                        
                        <Link to="/login">Login</Link>
                    </HeaderLinks>
                </PageHeader>
            )}
            <PublicPageContent>{children}</PublicPageContent>
            {!hideFooter && <PublicFooter />}
        </Page>
    );
};
