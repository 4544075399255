import { action, makeObservable } from "mobx";
import { JiraIntegrationStatus, JiraProject, JiraIssueStatus } from "./JiraIntegration";
import request from "util/request";
import { useContext, useState, useEffect } from "react";
import { contexts } from "AppProvider";
import * as Sentry from "@sentry/browser";

export const useJiraIntegrationStatus = () => {
    const thirdPartyIntegrationStore = useContext(contexts).thirdPartyIntegration;
    const [status, setStatus] = useState<JiraIntegrationStatus>(JiraIntegrationStatus.NOT_QUERIED);
    useEffect(() => {
        thirdPartyIntegrationStore?.getJiraIntegrationStatus().then(status => {
            if (status !== undefined) {
                setStatus(status);
            }
        });
    }, [thirdPartyIntegrationStore]);

    return status;
};

export const useJiraProjectMetadata = () => {
    const thirdPartyIntegrationStore = useContext(contexts).thirdPartyIntegration;
    const [metadata, setMetadata] = useState<JiraProject[]>([]);
    useEffect(() => {
        thirdPartyIntegrationStore?.jiraProjectMetadata().then(md => {
            if (md !== undefined) {
                setMetadata(md);
            }
        });
    }, [thirdPartyIntegrationStore]);

    return metadata;
};

export default class ThirdPartyIntegrationStore {
    private _jiraIntegrationStatus?: Promise<JiraIntegrationStatus>;
    private _jiraProjectMetadata?: Promise<JiraProject[]>;

    constructor() {
        makeObservable<ThirdPartyIntegrationStore, "updateJiraProjectMetadata" | "updateIntegrationStatus">(this);
    }

    @action.bound
    async refreshStatus() {
        this._jiraIntegrationStatus = this.updateIntegrationStatus();
        let status = await this._jiraIntegrationStatus;
        if (status === JiraIntegrationStatus.CONNECTED) {
            this._jiraProjectMetadata = this.updateJiraProjectMetadata();
        }
    }

    @action.bound
    private updateJiraProjectMetadata() {
        return request()
            .get(`/api/jira/projectsandtypes`)
            .handle(err => {
                if (err) {
                    Sentry.captureException(err);
                    return;
                }
            })
            .then(res => res.body)
            .catch(() => {});
    }

    @action.bound
    private updateIntegrationStatus() {
        return request()
            .get(`/api/jira/status`)
            .handle(err => {
                if (err) {
                    Sentry.captureException(err);
                    return;
                }
            })
            .then(res => res.body)
            .catch(() => {});
    }

    @action.bound
    public async jiraProjectMetadata() {
        if (!this._jiraProjectMetadata) {
            let jiraIntegrationStatus = await this.getJiraIntegrationStatus();
            if (jiraIntegrationStatus && jiraIntegrationStatus === JiraIntegrationStatus.CONNECTED) {
                this._jiraProjectMetadata = this.updateJiraProjectMetadata();
            }
        }
        return this._jiraProjectMetadata;
    }

    @action.bound
    public async getJiraIntegrationStatus() {
        if (!this._jiraIntegrationStatus) {
            this._jiraIntegrationStatus = this.updateIntegrationStatus();
        }
        return this._jiraIntegrationStatus;
    }

    @action.bound
    public getJiraIssueStatus(issueId: string, callback: (status: JiraIssueStatus) => void): Promise<any> {
        return request()
            .get<JiraIssueStatus>(`/api/jira/${issueId}`)
            .handle((err, res) => {
                if (err) {
                    // TODO log error
                    // toast.error("Oops, there was an error getting this Jira Issue's data");
                    return;
                }
                callback(res.body);
            })
            .catch(() => {});
    }
}
