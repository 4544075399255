import React from "react";
import { observer } from "mobx-react-lite";
import { ItemType, DraftStatus } from "shared/models/Item";
import { Link } from "react-router-dom";

export type LinkableItemProps = {
    type: ItemType;
    localKey: number;
    contextType?: ItemType;
    itemId: string;
    draftStatus?: DraftStatus;
    version?: number;
};

export function getReviewUrl({
    type,
    itemId,
    reviewItemId
}: {
    itemId: string;
    type: ItemType;
    reviewItemId?: string;
}): string {
    let url;
    switch (type) {
        case ItemType.Standard:
        case ItemType.Control:
            url = `/stack/review/${reviewItemId}/standard/${itemId}`;
            break;
        case ItemType.Document:
            url = `/stack/review/${reviewItemId}/doc/${itemId}`;
            break;
        case ItemType.Action:
            url = `/stack/review/${reviewItemId}/action/${itemId}`;
            break;
        default:
            url = `/`;
            break;
    }

    return url;
}

export function getUrl({ type, localKey, itemId, draftStatus, contextType, version }: LinkableItemProps): string {
    let url;
    switch (type) {
        case ItemType.Space:
            url = `/stack/space/${itemId}`;
            break;
        case ItemType.Action:
            url =
                draftStatus === DraftStatus.IsFirstDraft ? `/stack/action/${itemId}/draft` : `/stack/action/${itemId}`;
            break;
        case ItemType.Control:
        case ItemType.Standard:
            url = `/stack/standard/${itemId}`;
            break;
        case ItemType.Section:
            const urlSegment = contextType === ItemType.Document ? "doc" : "standard";
            url =
                draftStatus === DraftStatus.IsFirstDraft
                    ? `/stack/${urlSegment}/${itemId}/draft`
                    : `/stack/${urlSegment}/${itemId}`;
            if (draftStatus === DraftStatus.IsArchived) {
                url += `/version/${version}`;
            }
            break;
        case ItemType.Document:
            url = draftStatus === DraftStatus.IsFirstDraft ? `/stack/doc/${itemId}/draft` : `/stack/doc/${itemId}`;
            if (draftStatus === DraftStatus.IsArchived) {
                url += `/version/${version}`;
            }
            break;
        case ItemType.Review:
            url = `/stack/review/${itemId}`;
            break;
        case ItemType.TaskStep:
        case ItemType.Task:
            url = `/stack/task/${itemId}`;
            break;
        case ItemType.Questionnaire:
            url = `/stack/questionnaire/${itemId}`;
            break;
        case ItemType.Issue:
            url = `/stack/issue/${itemId}`;
            break;
        case ItemType.PseudoTask:
            // the localKey of a pseudo task is actually the ticks of the particular range it would be generated for
            url = `/stack/pseudotask/${itemId}/${localKey}`;
            break;
        case ItemType.Tag:
            url = `/stack/tag/${itemId}`;
            break;
        case ItemType.External:
            url = `/api/item/preview/${itemId}`;
            break;
        default:
            url = `/`;
            break;
    }

    switch (type) {
        case ItemType.Document:
        case ItemType.Standard:
        case ItemType.Section:
        case ItemType.Control:
            url += `?s=${localKey}`;
            break;
    }

    return url;
}

/** Wraps the item in a link for editing. */
export default observer(
    ({
        item,
        children,
        onClick,
        className
    }: {
        className?: string;
        onClick?: (e) => void;
        item: LinkableItemProps;
        children: React.ReactNode;
    }) => {
        const { type, itemId, draftStatus, contextType, localKey, version } = item;

        const url = getUrl({ type, itemId, draftStatus, contextType, localKey, version });
        if (!url) {
            return <>{children}</>;
        }

        if (type === ItemType.External) {
            // this relies on a server redirect, so it has to use `a` instead of `Link`
            return (
                <a href={url} onClick={onClick} className={className} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        } else {
            return (
                <Link onClick={onClick} to={url} className={className}>
                    {children}
                </Link>
            );
        }
    }
);
