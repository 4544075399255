import React from "react";
import { statusNameMap, statusColorMap } from "stack/workflow/WorkflowStatusMaps";
import styles from "./Badge.module.css";
import { WorkflowStatusType } from "shared/models/workflow/Workflow";
import moment from "moment";
import { Icon } from "semantic-ui-react";

export default ({
    status,
    draftStatus,
    completedUtc,
    onClick
}: {
    status: WorkflowStatusType;
    completedUtc?: string | Date | null;
    draftStatus?: WorkflowStatusType;
    onClick?: () => void;
}) => {
    const hasDraftStatus = draftStatus != null;
    return (
        <>
            <span
                onClick={onClick}
                className={hasDraftStatus ? styles.badgeWithDraftStatus : styles.badge}
                style={{ background: statusColorMap[status] }}
            >
                {!completedUtc && statusNameMap[status]}
                {completedUtc && (
                    <>
                        <Icon name="check" />
                        {status === WorkflowStatusType.InScope
                            ? statusNameMap[status]
                            : moment
                                  .utc(completedUtc)
                                  .local()
                                  .format("MMM D")
                                  .toUpperCase()}
                    </>
                )}
            </span>
            {hasDraftStatus && (
                <span className={styles.draftStatusBadge} style={{ background: statusColorMap[draftStatus!] }}>
                    {hasDraftStatus && (
                        <div style={{ borderLeftColor: statusColorMap[status] }} className={styles.badgeArrow}></div>
                    )}
                    {statusNameMap[draftStatus!]}
                </span>
            )}
        </>
    );
};
