import React from "react";
import styles from "./Badge.module.css";
import { Icon } from "semantic-ui-react";
import moment from "moment";

export default ({ endUtc, overdue, year }: { overdue?: boolean; year?: boolean; endUtc: string | Date }) => {
    return (
        <span
            className={styles.dateBadge}
            style={{ color: overdue ? "white" : "black", background: overdue ? "#d31515" : "" }}
        >
            <Icon name="calendar alternate outline" />
            {moment
                .utc(endUtc)
                .local()
                .format(!year ? "MMM D" : "MMM D, YYYY")
                .toUpperCase()}
        </span>
    );
};
