import { Activity, ActivityType } from "shared/models/activities/Activity";
import styled from "styled-components";

export type ActivityRenderer = [
    ActivityType,
    ((props: Activity & { userName: string; time: string }) => JSX.Element) | undefined,
    ((props: Activity & { userName: string; time: string }) => JSX.Element) | undefined,
    boolean?
];

export interface ActivityElement {
    modifiedBy?:
        | ((
              props: Activity & {
                  userName: string;
                  time: string;
              }
          ) => JSX.Element)
        | undefined;
    subject?:
        | ((
              props: Activity & {
                  userName: string;
                  time: string;
              }
          ) => JSX.Element)
        | undefined;
    override?: boolean;
}

export interface ActivityPlugin {
    // Should this plugin be used for this activity
    applies?: (activity: Activity) => boolean;

    // If the plugin applies to the activity, use this if we want to hide the activity
    exclude?: (activity: Activity) => boolean;

    // Map to return an element for each activity type
    activities?: Map<ActivityType, ActivityElement>;
}

export const AtTime = styled.span`
    &:before {
        content: "at ";
    }

    opacity: 0.5;
    margin-left: 3px;
    font-size: 0.9em;
`;
