import React from "react";

type ClientListItem = { id: string; name: string };

type ClientsListProps = {
    clients: Array<ClientListItem>;
    activeClient: ClientListItem;
    onClick: (client: ClientListItem) => void;
};

export default function ClientsList({ activeClient, clients, onClick }: ClientsListProps) {
    return (
        <div>
            {clients.map(client => (
                <div key={client.id}>
                    {client.id === activeClient.id ? (
                        <p style={{ fontWeight: "bold" }}>{client.name}</p>
                    ) : (
                        <button onClick={() => onClick(client)} className="ui link">
                            {client.name}
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
}
