import { action, computed, IObservableArray, makeObservable, observable } from "mobx";
import Loader from "util/Loader";
import request from "util/request";
import { Activity } from "./activities/Activity";
import { ContentSearchItem } from "./search/SearchRequest";

export enum NotificationType {
    AlmostDue = 1,
    Overdue = 2,
    Activity = 3,
    OverdueIssueCreated = 4,
    ReviewedIssueOverdue = 5
}

export interface Notification {
    id: string;
    type: NotificationType;
    subjectId: string;
    actorId: string;
    data: any;
    item?: ContentSearchItem;
    activity?: Activity;
    notificationUtc;
    isRead: boolean;
}

export default class NotificationStore {
    loader = new Loader(false, { immediate: true });

    @observable notifications: IObservableArray<Notification> = observable([]);

    constructor() {
        makeObservable(this);
    }

    load() {
        return this.loader.load(
            request()
                .get(`/api/notification/entityUser`)
                .handle((err, res) => {
                    if (err) return;
                    this.notifications.replace(res.body);
                })
        );
    }

    @computed get unreadCount() {
        return this.notifications.filter(n => !n.isRead).length;
    }

    @action.bound dismiss(notificationId) {
        request()
            .post(`/api/notification/${notificationId}/dismiss`)
            .handle(err => {
                if (err) return;
                const notification = this.notifications.find(n => n.id === notificationId);
                if (notification) {
                    this.notifications.remove(notification);
                }
            });
    }

    @action.bound read() {
        request()
            .post(`/api/notification/read`)
            .send(this.notifications.map(n => n.id))
            .handle(err => {
                if (err) return;
                this.notifications.forEach(n => (n.isRead = true));
            });
    }
}
