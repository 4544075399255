import React from "react";
import LoginForm from "../components/LoginForm";
import PublicPage from "shared/components/content/PublicPage";

export default () => {
    return (
        <PublicPage title="Login">
            <LoginForm />
        </PublicPage>
    );
};
