import { action, observable, makeObservable } from "mobx";
import request from "util/request";

class TwoFactorStore {
    @observable methodOptions;
    @observable codeSent = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setCodeSent(value) {
        this.codeSent = value;
    }

    @action.bound
    load() {
        request()
            .get("/api/session/twofactor/send/form")
            .handle((err, res) => {
                if (err) return;
                this.methodOptions = res.body;
            });

        return Promise.resolve();
    }
}

export default TwoFactorStore;
