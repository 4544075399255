import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Container, Button, Form, Message, Icon } from "semantic-ui-react";
import request from "util/request";
import { forEach } from "lodash";
import { useForm, Controller } from "react-hook-form";

interface FormData {
    email: string;
    password: string;
}

export default observer(() => {
    const {
        handleSubmit,
        control,
        setError,
        formState: { isSubmitting, errors }
    } = useForm<FormData>();
    const [sentEmail, setSentEmail] = useState<boolean>();
    const [formError, setFormError] = useState<string>();

    const onSubmit = (data: FormData) => {
        return request()
            .post("/api/session/forgotpassword")
            .send(data)
            .handle((err, res) => {
                if (err) {
                    if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof FormData, { message });
                        });
                    } else {
                        setFormError("There was an error resetting your password. Please try again later.");
                    }
                    return;
                }

                setSentEmail(true);
                setFormError(undefined);
            })
            .catch(err => {
                // don't bubble these errors to sentry
                if (err.status === 400) {
                } else {
                    throw err;
                }
            });
    };
    return (
        <Container text>
            <Message attached info>
                <Message.Header>
                    <Icon name="redo" /> Forgot Password
                </Message.Header>
            </Message>
            {sentEmail && (
                <Message attached>
                    <Message.Header>Password Reset Email Sent</Message.Header>
                    <p>A password reset link has been sent to your specified email address. </p>
                </Message>
            )}
            {formError && (
                <Message attached negative>
                    <Message.Header>Reset Password Failed</Message.Header>
                    {formError}
                </Message>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} className="attached segment">
                <Controller
                    name="email"
                    render={({ field: { value, onChange }}) => {
                        return (
                            <Form.Input
                                value={value}
                                onChange={onChange}
                                label="Email"
                                error={errors.email && { content: errors.email.message }}
                            />
                        );
                    }}
                    defaultValue=""
                    control={control}
                    rules={{ required: "Email is required" }}
                />
                <Button loading={isSubmitting} type="submit">
                    Reset Password
                </Button>
            </Form>
        </Container>
    );
});
