import JiraTokenGenerator from "jira-connect-app/components/JiraTokenGenerator";
import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Form, Message, Segment } from "semantic-ui-react";
import PrivatePage from "shared/components/content/PrivatePage";

export default observer(() => {
    return (
        <PrivatePage title="Jira Integration">
            <div>
                <Container text style={{ padding: "2em 0" }}>
                    <Message attached info>
                        <Message.Header>Configure your Jira Integration</Message.Header>
                    </Message>
                    <Form className="attached segment">
                        <JiraTokenGenerator />
                    </Form>
                </Container>
            </div>
        </PrivatePage>
    );
});
