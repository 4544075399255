import React, { useState } from "react";
import { Container, Button, Form, Message, Icon } from "semantic-ui-react";
import ForgotPasswordLink from "./ForgotPasswordLink";
import { useForm, Controller } from "react-hook-form";
import request from "util/request";
import { forEach } from "lodash";
import sessionChangeRedirect from "util/sessionChangeRedirect";

interface FormData {
    email: string;
    password: string;
}

export default () => {
    const {
        handleSubmit,
        control,
        setError,
        formState: { isSubmitting, errors }
    } = useForm<FormData>();
    const [formError, setFormError] = useState<string>();

    const onSubmit = (data: FormData) => {
        return request()
            .post("/api/session/login")
            .send(data)
            .handle((err, res) => {
                if (err) {
                    if (err.status === 401) {
                        setFormError("There was an error with that email or password.");
                    } else if (res?.body?.errors) {
                        forEach(res.body.errors, (message, name) => {
                            setError(name as keyof FormData, { message });
                        });
                    } else {
                        setFormError("There was an error logging in. Please try again later.");
                    }
                    return;
                }

                sessionChangeRedirect();
            })
            .catch(err => {
                // don't bubble these errors to sentry
                if (err.status === 400 || err.status === 401) {
                } else {
                    throw err;
                }
            });
    };

    return (
        <Container text>
            <Message attached info>
                <Message.Header>
                    <Icon name="lock" /> Login
                </Message.Header>
            </Message>
            {formError && (
                <Message attached negative>
                    <Message.Header>Login Failed</Message.Header>
                    {formError}
                </Message>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} className="attached segment">
                <Controller
                    name="email"
                    render={({ field: { value, onChange } }) => {
                        return (
                            <Form.Input
                                value={value}
                                onChange={onChange}
                                label="Email"
                                error={errors.email && { content: errors.email.message }}
                            />
                        );
                    }}
                    defaultValue=""
                    control={control}
                    rules={{ required: "Email is required" }}
                />
                <Form.Field>
                    <Controller
                        name="password"
                        render={({ field: { value, onChange } }) => {
                            return (
                                <Form.Input
                                    value={value}
                                    onChange={onChange}
                                    label="Password"
                                    type="password"
                                    error={errors.password && { content: errors.password.message }}
                                />
                            );
                        }}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Password is required" }}
                    />
                    <label>
                        <ForgotPasswordLink />
                    </label>
                </Form.Field>
                <Button loading={isSubmitting} type="submit">
                    Login
                </Button>
            </Form>
        </Container>
    );
};
